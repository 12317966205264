import { dev, localhost, pre, prod, stg } from "../constants/domain";

export const UtilsBrowser = {
  isInternetExplorer: (): boolean => {
    const ua = window.navigator.userAgent;

    // MSIE: IE 10 or older
    // Trident: IE 11
    if (ua.indexOf("MSIE ") > 0 || ua.indexOf("Trident/") > 0) {
      return true;
    }

    return false;
  },
};

export const getDomain = (hostname: string) => {
  let domain = "";
  switch (hostname) {
    case localhost:
      domain = localhost;
      break;
    case dev:
      domain = dev;
      break;
    case stg:
      domain = stg;
      break;
    case pre:
      domain = pre;
      break;
    case prod:
      domain = prod;
      break;
    default:
      domain = prod;
  }
  return domain;
};
