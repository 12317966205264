import { isEmpty } from "lodash";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ApplicationState } from "../store";
import { PrivacyState } from "../store/reducers/privacy-reducer";
import { UserState } from "../store/reducers/user-reducer";
import { CountryWithRegion, LANGUAGE_VIEW } from "../types/countries";
import { UtilsLocale } from "../utils/UtilsLocale";
import { actionSetPrivacyLanguageRegion } from "../store/actions/privacy-actions";
import { Cookie } from "../services/storage";
import { PP_LANGUAGE, PP_REGION } from "../constants/storage";
import { useCookie } from "./cookie-hooks";

export const usePrivacyPolicy = () => {
  const dispatch = useDispatch();
  const { isFCAllowed } = useCookie();
  const userState = useSelector<ApplicationState, UserState>(
    (state) => state.user
  );
  const privacyState = useSelector<ApplicationState, PrivacyState>(
    (state) => state.privacy
  );

  const { userCountryCode } = userState;

  useEffect(
    () => {
      if (userCountryCode !== "") {
        const countryDataGeoIP = UtilsLocale.getCountryDataFromJson({
          countryCode: userCountryCode,
          languageView: LANGUAGE_VIEW.PP_LANGUAGE,
        }) as CountryWithRegion;
        if (!isEmpty(countryDataGeoIP) && !privacyState.language) {
          const { languages, code, region } = countryDataGeoIP;
          updatePrivacyPolicyLanguage(
            `${languages[0].code}-${code}`,
            region,
            true
          );
        }
      }
    },
    // eslint-disable-next-line
    [userCountryCode, privacyState.language]
  );

  const updatePrivacyPolicyLanguage = (
    language: string,
    region: string,
    saveCookie = false
  ) => {
    if (saveCookie) {
      if (isFCAllowed) {
        Cookie.set(PP_REGION, region, { expires: 365 });
        Cookie.set(PP_LANGUAGE, language, { expires: 365 });
      }
    }

    dispatch(
      actionSetPrivacyLanguageRegion({
        language,
        region,
      })
    );
  };

  return { updatePrivacyPolicyLanguage };
};
