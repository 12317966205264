import { RequestDetailsEntity } from "../types/request-details";

export const UtilsDateTime = {
  convertJoinedDate: (joinedDate: number): string => {
    if (joinedDate === 0) {
      return "";
    }
    const date = joinedDate.toString();
    const year = date.slice(0, 4);
    const month = date.slice(4, 6);
    const day = date.slice(6, 8);

    return `${year}.${month}.${day}`;
  },

  convertDateToTimezone: (date: Date) => {
    const offset = new Date().getTimezoneOffset();
    return new Date(date.getTime() + offset * -60000);
  },

  parseDate: (date: string, isBirthday = false) => {
    const convertDate = date.toString();
    const year = parseInt(convertDate.slice(0, 4));
    const month = parseInt(convertDate.slice(4, 6), 10) - 1; // JS months 0-11
    const day = parseInt(convertDate.slice(6, 8), 10);
    const hh = parseInt(convertDate.slice(8, 10), 10);
    const mi = parseInt(convertDate.slice(10, 12), 10);
    const ss = parseInt(convertDate.slice(12, 14), 10);

    let newDate = new Date();

    if (isBirthday) {
      newDate = new Date(year, month, day);
    } else {
      newDate = new Date(year, month, day, hh, mi, ss);
    }

    return UtilsDateTime.convertDateToTimezone(newDate);
  },

  convertDateString: (date: string): string => {
    if (!date) return "";
    const newDate: Date = UtilsDateTime.parseDate(date);
    return `${newDate.getFullYear()}.${("0" + (newDate.getMonth() + 1)).slice(
      -2
    )}.${("0" + newDate.getDate()).slice(-2)} ${(
      "0" + (newDate.getHours() % 12 <= 1 ? "12" : newDate.getHours() % 12)
    ).slice(-2)}:${("0" + newDate.getMinutes()).slice(-2)} ${
      newDate.getHours() < 12 ? " AM" : " PM"
    }`;
  },

  getLocalTimezone: () => {
    const offset = new Date().getTimezoneOffset();
    const offsetHour = Math.floor(Math.abs(offset / 60));
    const offsetMin = Math.abs(offset) - offsetHour * 60;

    let offsetStr = offset > 0 ? "-" : "+";
    offsetStr =
      offsetHour < 10
        ? `${offsetStr}0${offsetHour}:`
        : `${offsetStr}${offsetHour}:`;
    offsetStr =
      offsetMin < 10 ? `${offsetStr}0${offsetMin}` : `${offsetStr}${offsetMin}`;

    return offsetStr;
  },

  dateObjectToStringUTC: (dateObj: Date): string => {
    const pad = (number: any) => (number < 10 ? `0${number}` : number);

    return (
      dateObj.getUTCFullYear() +
      pad(dateObj.getUTCMonth() + 1) +
      pad(dateObj.getUTCDate()) +
      pad(dateObj.getUTCHours()) +
      pad(dateObj.getUTCMinutes()) +
      pad(dateObj.getUTCSeconds())
    );
  },

  getTargetDate: (date: string): string => {
    const months = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    const newDate = UtilsDateTime.parseDate(date);
    newDate.setDate(newDate.getDate() + 7);

    const year = newDate.getFullYear();
    const month = months[newDate.getMonth()];
    const day = newDate.getDate();

    return `${month} ${day}, ${year}`;
  },

  formatDate: (timestamp: string): string => {
    if (!timestamp) return "";
    // localize the timezone for timestamp
    const newDate = new Date(timestamp + "Z");
    const formattedDate = newDate.toLocaleString("en-US", {
      month: "short",
      day: "2-digit",
      year: "numeric",
      hour: "2-digit",
      minute: "2-digit",
      hour12: true,
    });
    return formattedDate;
  },

  getExpiredDate: (request: RequestDetailsEntity): string => {
    let date: any = null;
    if (request && request.sub_request_list.length > 0) {
      request.sub_request_list.forEach((service) => {
        if (service.attach_file && service.attach_file.length > 0) {
          if (date < service.attach_file[0].file_expire) {
            date = service.attach_file[0].file_expire;
          }
        }
      });
    }
    return date as string;
  },
};
