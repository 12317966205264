import React from "react";
import classNames from "classnames";

import "./Alert.scss";
import { Overlay } from "../Overlay/Overlay";
import { Svg, SVG_ICONS } from "../Svg/Svg";
import { Typography } from "../Typography/Typography";
import { useEffect } from "react";
import { TTSButton } from "../_controls";

interface AlertSubComponents {
  Text: React.FC<TextProps>;
  SubText: React.FC<SubTextProps>;
  Icon: React.FC<IconProps>;
  Footer: React.FC<FooterProps>;
}

interface TextProps {
  children?: string | React.ReactNode;
  className?: string;
}

interface SubTextProps {
  children?: string | React.ReactNode;
  className?: string;
}

interface IconProps {
  icon?: string;
  width?: number;
  height?: number;
  className?: string;
}

interface FooterProps {
  children?: React.ReactNode;
  className?: string;
}

interface Props {
  title?: string;
  show?: boolean;
  children?: React.ReactNode;
  wrapperClassname?: string;
  customClose?: React.ReactNode;
  onHide?: () => void;
  onHideTestId?: string;
  ttsButtonId?: string;
  id?: string;
  tabIndex?: number;
  isDialogModal?: boolean;
  testId?: string;
}

const Alert: React.FC<Props> & AlertSubComponents = (props) => {
  const {
    show = false,
    children,
    wrapperClassname,
    title,
    customClose,
    ttsButtonId,
    id,
    tabIndex,
    testId,
    onHideTestId,
    isDialogModal = false,
  } = props;

  const wrapClass = classNames(
    "alert-wrapper",
    { open: !!show },
    wrapperClassname
  );

  useEffect(() => {
    const body = document.body;
    if (show) {
      body.classList.add("alert-open");
    } else {
      body.classList.remove("alert-open");
    }
  }, [show]);

  return (
    <div className={wrapClass} id={id} tabIndex={tabIndex} data-testid={testId}>
      <Overlay open={show} />
      <div className="alert-content">
        <div className="alert">
          <div className="alert-header">
            <Typography
              variant="title2"
              component="div"
              className="alert-header__title"
            >
              {title}
              {title && !isDialogModal && (
                <TTSButton
                  value={title}
                  className="tts-button-modal"
                  id={ttsButtonId}
                />
              )}
            </Typography>
            {customClose ? (
              customClose
            ) : (
              <button
                data-testid={onHideTestId}
                className="alert-header__close__btn"
                onClick={props.onHide}
                aria-label="alert-header-close-btn"
              >
                <Svg icon={SVG_ICONS.DRAWER_CLOSE} width={24} height={24} />
              </button>
            )}
          </div>
          {children}
        </div>
      </div>
    </div>
  );
};

const Text: React.FC<TextProps> = (props) => {
  return (
    <Typography
      className={classNames("alert-text", props.className)}
      variant="title2"
      component="p"
    >
      {props.children}
    </Typography>
  );
};

const SubText: React.FC<SubTextProps> = (props) => {
  return (
    <Typography
      className={classNames("alert-sub-text", props.className)}
      variant="flag"
      component="p"
    >
      {props.children}
    </Typography>
  );
};

const Icon: React.FC<IconProps> = (props) => {
  const { icon = "", width = 64, height = 64 } = props;

  return (
    <div className="alert-icon">
      <Svg icon={icon} width={width} height={height} />
    </div>
  );
};

const Footer: React.FC<FooterProps> = (props) => (
  <div className="alert-footer">{props.children}</div>
);

Alert.Text = Text;
Alert.SubText = SubText;
Alert.Icon = Icon;
Alert.Footer = Footer;

export default Alert;
