import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Col, Container, Row } from "../../../../../components/Grid/Grid";
import { Typography } from "../../../../../components/__common/Typography/Typography";
import { ROUTES } from "../../../../../configs/routes";
import {
  EVENT_CATEGORY_HOME_PAGE,
  EVENT_HOME_ACTION_GPS,
  EVENT_HOME_ACTION_SECURITY_PRIVACY,
} from "../../../../../constants/analytics";
import { SVG_ICONS, Svg } from "../../../../../components/__common/Svg/Svg";
import { Link } from "react-router-dom";
import "./PersonalDataManagement.scss";
import { useSelector } from "react-redux";
import { ApplicationState } from "../../../../../store";
import { UserState } from "../../../../../store/reducers/user-reducer";
import { LayoutState } from "../../../../../store/reducers/layout-reducer";
import { UtilsHome } from "../../../../../utils/UtilsHome";
import GoogleAnalytics from "../../../../../utils/GoogleAnalytics";

const PersonalDataManagement: React.FC = () => {
  const { t } = useTranslation();
  const userState = useSelector<ApplicationState, UserState>(
    (state) => state.user
  );

  const layoutState = useSelector<ApplicationState, LayoutState>(
    (state) => state.layout
  );

  const [sustainabilityLink, setSustainabilityLink] = useState<string>("");
  useEffect(() => {
    let link: string = UtilsHome.getSustainabilityLink(
      layoutState.countryCode,
      layoutState.languageCode
    );

    if (!userState.isGeoIPLoading) {
      link = UtilsHome.getSustainabilityLink(
        userState.userCountryCode,
        layoutState.languageCode
      );
    }

    setSustainabilityLink(link);
  }, [
    userState.userCountryCode,
    layoutState.languageCode,
    userState.isGeoIPLoading,
    layoutState.countryCode,
  ]);
  return (
    <>
      <div className="home-bottom-banner">
        <Container>
          <Typography
            variant="h3"
            component="h3"
            className="home-bottom-banner__title"
          >
            {t("PRIVACY_HOME_BODY_DATA_MANAGEMENT_TITLE")}
          </Typography>
          <Container>
            <Row className="home-bottom-banner__row">
              <>
                <Col xl={6} sm={12}>
                  <Link
                    to={ROUTES.ABOUT_GRPS}
                    className="home-bottom-banner__col"
                    onClick={() =>
                      GoogleAnalytics.triggerEvent(
                        EVENT_CATEGORY_HOME_PAGE,
                        EVENT_HOME_ACTION_GPS
                      )
                    }
                  >
                    <Svg
                      className="home-bottom-banner__col__icon"
                      icon={SVG_ICONS.RIGHTS_RESPONSE}
                      width={93}
                      height={93}
                    />
                    <Typography
                      variant="title2"
                      className="home-bottom-banner__col__title"
                    >
                      {t("PRIVACY_HOME_BODY_DATA_MANAGEMENT_GRRS")}
                    </Typography>
                    <Typography
                      variant="body1"
                      className="home-bottom-banner__col__caption"
                    >
                      {t("PRIVACY_HOME_BODY_DATA_MANAGEMENT_GRRS_TEXT")}
                    </Typography>
                  </Link>
                </Col>
                <Col xl={6} sm={12}>
                  <a
                    href={sustainabilityLink}
                    rel="noreferrer"
                    target="_blank"
                    className="home-bottom-banner__col"
                    onClick={() =>
                      GoogleAnalytics.triggerEvent(
                        EVENT_CATEGORY_HOME_PAGE,
                        EVENT_HOME_ACTION_SECURITY_PRIVACY
                      )
                    }
                  >
                    <Svg
                      className="home-bottom-banner__col__icon"
                      icon={SVG_ICONS.ABOUT_PRIVACY}
                      width={93}
                      height={93}
                    />
                    <Typography
                      variant="title2"
                      className="home-bottom-banner__col__title"
                    >
                      {t("PRIVACY_HOME_BODY_DATA_MANAGEMENT_ABOUT_PRIVACY")}
                    </Typography>
                    <Typography
                      variant="body1"
                      className="home-bottom-banner__col__caption"
                    >
                      {t(
                        "PRIVACY_HOME_BODY_DATA_MANAGEMENT_ABOUT_PRIVACY_TEXT"
                      )}
                    </Typography>
                  </a>
                </Col>
              </>
            </Row>
          </Container>
        </Container>
      </div>
    </>
  );
};

export default PersonalDataManagement;
