import { MyDataHistoryEntity, MyDataHistoryReqBody } from "../../types/history";
import {
  SummaryEntity,
  RequestEntity,
  ServiceRequestErrorEntity,
} from "../../types/mydata";
import { RequestDetailsEntity } from "../../types/request-details";
import { MYDATA_ACTION_TYPES } from "../actions-types";
import { CustomAction } from "../index";

export const actionSetSummary = (data: SummaryEntity): CustomAction => {
  return {
    type: MYDATA_ACTION_TYPES.SET_SUMMARY,
    data,
  };
};

export const actionSetSummaryLoading = (): CustomAction => {
  return {
    type: MYDATA_ACTION_TYPES.SET_SUMMARY_LOADING,
  };
};

export const confirmRequestData = (data: RequestEntity): CustomAction => {
  return {
    type: MYDATA_ACTION_TYPES.SET_REQUEST,
    data,
  };
};
export const actionSetRequestConfirmationDetails = (
  data: string
): CustomAction => {
  return {
    type: MYDATA_ACTION_TYPES.SET_REQUEST_CONFIRMATION_DETAILS,
    data,
  };
};

export const actionSetRequestLoading = (): CustomAction => {
  return {
    type: MYDATA_ACTION_TYPES.SET_REQUEST_PENDING,
  };
};

export const actionRequestData = (data: RequestEntity): CustomAction => {
  return {
    type: MYDATA_ACTION_TYPES.SET_REQUEST,
    data,
  };
};
export const actionSetRequestDetails = (
  data: RequestDetailsEntity
): CustomAction => {
  return {
    type: MYDATA_ACTION_TYPES.SET_REQUEST_DETAILS,
    data: data,
  };
};

export const actionSetRequestDetailsLoading = (): CustomAction => {
  return {
    type: MYDATA_ACTION_TYPES.SET_REQUEST_DETAILS_LOADING,
  };
};

export const actionSetFilePasswordSend = (data: boolean): CustomAction => {
  return {
    type: MYDATA_ACTION_TYPES.SET_FILE_PASSWORD_SEND,
    data,
  };
};
export const actionSetCancelRequestComplete = (data: boolean): CustomAction => {
  return {
    type: MYDATA_ACTION_TYPES.SET_CANCEL_REQUEST_COMPLETE,
    data,
  };
};

export const actionSetCancelRequestPending = (data: boolean): CustomAction => {
  return {
    type: MYDATA_ACTION_TYPES.SET_CANCEL_REQUEST_PENDING,
    data,
  };
};
export const actionSetIsUserEmailNotSet = (data: boolean): CustomAction => {
  return {
    type: MYDATA_ACTION_TYPES.SET_USER_EMAIL_IS_NOT_SET,
    data,
  };
};

export const actionSetMyDataHistory = (
  data: MyDataHistoryEntity
): CustomAction => {
  return {
    type: MYDATA_ACTION_TYPES.SET_MYDATA_HISTORY,
    data,
  };
};
export const actionSetLatestRequestHistory = (
  data: RequestDetailsEntity
): CustomAction => {
  return {
    type: MYDATA_ACTION_TYPES.SET_LATEST_REQUEST_TO_HISTORY,
    data,
  };
};
export const actionSetMyDataHistoryLoading = (): CustomAction => {
  return {
    type: MYDATA_ACTION_TYPES.SET_MYDATA_HISTORY_LOADING,
  };
};

export const actionSetMyDataLoadMoreHistory = (
  data: MyDataHistoryEntity
): CustomAction => {
  return {
    type: MYDATA_ACTION_TYPES.SET_MYDATA_LOADMORE_HISTORY,
    data,
  };
};

export const actionSetHasMoreHistory = (data: boolean): CustomAction => {
  return {
    type: MYDATA_ACTION_TYPES.SET_MYDATA_HAS_MORE_HISTORY,
    data,
  };
};

export const actionSetHistoryMoreLoading = (data: boolean): CustomAction => {
  return {
    type: MYDATA_ACTION_TYPES.SET_MYDATA_HISTORY_MORE_LOADING,
    data,
  };
};

export const actionSetHistoryReqBody = (
  data: MyDataHistoryReqBody
): CustomAction => {
  return {
    type: MYDATA_ACTION_TYPES.SET_MYDATA_HISTORY_REQ_BODY,
    data,
  };
};

export const actionSetGlobalSiteLinkPopupClose = (): CustomAction => {
  return {
    type: MYDATA_ACTION_TYPES.SET_MYDATA_GLOBAL_SITE_LINK_POPUP_CLOSE,
  };
};

export const actionSetMyDataHistoryLoaded = (data: boolean): CustomAction => {
  return {
    type: MYDATA_ACTION_TYPES.SET_MYDATA_HISTORY_LOADED,
    data,
  };
};

export const actionSetShowServiceToastError = (
  data: ServiceRequestErrorEntity
): CustomAction => {
  return {
    type: MYDATA_ACTION_TYPES.SET_IS_SHOW_SERVICE_TOAST_ERROR,
    data,
  };
};

export const actionSetIsSummaryLoaded = (data: boolean): CustomAction => {
  return {
    type: MYDATA_ACTION_TYPES.SET_SUMMARY_LOADED,
    data,
  };
};
