import React, { Fragment, useState, useEffect } from "react";
import { useSelector } from "react-redux";

import "./RequestDetailsModal.scss";
import { Container, Row, Col } from "../../../../components/Grid/Grid";
import Modal from "../../../../components/__common/Modal/Modal";
import { Svg, SVG_ICONS } from "../../../../components/__common/Svg/Svg";
import { Typography } from "../../../../components/__common/Typography/Typography";
import { Button } from "../../../../components/__common/_controls";
import { Image } from "../../../../components/__common/Image";
import { RequestStatus, RequestType } from "../../../../types/mydata";
import Alert from "../../../../components/__common/Alert/Alert";
import { TitleSection } from "./TitleSection";
import { Steps } from "./Steps";
import { useMyDataHooks } from "../../../../hooks/mydata-hooks";
import { ApplicationState } from "../../../../store";
import { MyDataState } from "../../../../store/reducers/mydata-reducer";
import { RequestDetailsEntity } from "../../../../types/request-details";
import { UtilsDateTime } from "../../../../utils/UtilsDateTIme";
import { UtilsConverter } from "../../../../utils/UtilsConverter";
import { UserState } from "../../../../store/reducers/user-reducer";
import Loading from "../../../../assets/images/gif/sdap-loading.json";
import { REQUEST_TYPE } from "../../../../types/user";
import { REQUEST_STATUS } from "../../../../constants/status";
import { useTranslation } from "react-i18next";
import { Cookie } from "../../../../services/storage";
import {
  REQUEST_NUMBER,
  SHOW_SURVEY,
  USER_SURVEY_SKIP,
} from "../../../../constants/storage";
import {
  EVENT_ACTION_DETAILS_DOWNLOAD_FILE,
  EVENT_CATEGORY_REQUEST_DETAILS,
} from "../../../../constants/analytics";
import { UtilsKeyboardAccessibility } from "../../../../utils/UtilsKeyboardAccessibility";
import FocusTrapping from "../../../../components/__hoc/FocusTrapping";
import {
  DISABLED_FEATURES,
  useFeatureToggle,
} from "../../../../utils/feature-toggle/useFeatureToggle";
import GoogleAnalytics from "../../../../utils/GoogleAnalytics";
import Lottie from "lottie-react";

interface Props {
  show: boolean;
  requestNumber: string;
  onHide?: () => void;
  onCompleteCancel?: () => void;
  headerTestid?: string;
}

export const RequestDetailsModal: React.FC<Props> = (props) => {
  const { t } = useTranslation();
  const {
    loadRequestDetails,
    sendFilePassword,
    cancelRequest,
    closCancelRequestModal,
    closeFilePasswordModal,
  } = useMyDataHooks();
  const { isDisabled } = useFeatureToggle();

  const myDataState = useSelector<ApplicationState, MyDataState>(
    (state) => state.myData
  );
  const userState = useSelector<ApplicationState, UserState>(
    (state) => state.user
  );

  const { userData } = userState;
  const {
    details,
    isCancelRequestComplete,
    isRequestDetailsLoading,
    isCancelRequestPending,
    isFilePasswordSent,
    isUserEmailNotSet,
  } = myDataState;

  const [status, setStatus] = useState<RequestStatus>(
    RequestStatus.REQUESTING || RequestStatus.QUEUED
  );
  const [requestType, setRequestType] = useState<RequestType>(
    RequestType.DOWNLOAD
  );

  const [isModalOpen, setModalOpen] = useState<boolean>(false);
  const [isCancelAlertOpen, setCancelAlertOpen] = useState<boolean>(false);
  const [isSentFilePasswordOpen, setSentFilePasswordOpen] =
    useState<boolean>(false);

  const {
    show = false,
    requestNumber,
    onHide,
    onCompleteCancel,
    headerTestid,
  } = props;

  const modalTitle =
    requestType === RequestType.DOWNLOAD
      ? "PRIVACY_REQUEST_DETAILS_TITLE_DOWNLOAD"
      : requestType === RequestType.DELETE
      ? "PRIVACY_REQUEST_DETAILS_TITLE_DELETE"
      : "PRIVACY_REQUEST_DETAILS_TITLE_OPTOUT";

  const handleOnClickHideModal = () => {
    if (onHide) {
      handleSurveyCookie();
      onHide();
    }
    closeFilePasswordModal();
  };

  const getFocusTrappingElements = () => {
    switch (requestType) {
      case RequestType.DOWNLOAD:
        return "div.request-detail-modal, div.modal-title, #request-details-tts, button.modal-header__close__btn, button.request-details__button__single, #close-details-modal, div.modal-body ";

      default:
        return "div.request-detail-modal, div.modal-title, #request-details-tts, #close-details-modal, button.modal-header__close__btn, div.modal-body, button.tts-button-container, button.btn-close, button.request-details__button__single";
    }
  };

  const handleSurveyCookie = () => {
    if (isDisabled(DISABLED_FEATURES.SURVEY)) return;

    const siteLanguageRequested = details.lang_trns.split("-")[0];
    const requestComplete = details.request_status === REQUEST_STATUS.COMPLETED;
    // const cookieShowSurvey = Cookie.get(SHOW_SURVEY);
    const skippedUser = Cookie.get(USER_SURVEY_SKIP) || "[]";
    const skippedUserArr = JSON.parse(skippedUser);
    const userLogin = userState.userData.loginId;
    const userHasSkipped =
      skippedUserArr.findIndex((item: string) => userLogin === item) > -1;

    if (
      !userHasSkipped &&
      !userState.userSurvey.hasAnsweredSurvey &&
      (siteLanguageRequested === "en" || siteLanguageRequested === "ko") &&
      requestComplete
    ) {
      Cookie.set(SHOW_SURVEY, "true", { expires: 186 });
    }
  };

  const handleOnCompleteCancel = () => {
    if (onCompleteCancel) {
      onCompleteCancel();
    }
  };

  const handleOnClickCancel = () => {
    setModalOpen(false);
    setCancelAlertOpen(true);
  };

  const handleOnClickTransferPassword = async () => {
    if (userData.mail) {
      await sendFilePassword(details.request_number);
    }
    setSentFilePasswordOpen(true);
    setModalOpen(false);
  };

  const handleOnClickConfirmCancel = async () => {
    await cancelRequest(details.request_number);
    loadRequestDetails(requestNumber);
    setCancelAlertOpen(false);
  };

  useEffect(() => {
    setModalOpen(show);
  }, [show]);

  const handleOnHideAlert = () => {
    setSentFilePasswordOpen(false);
    setModalOpen(true);
  };

  useEffect(
    () => {
      if (requestNumber && !userState.isGetUserDataLoading) {
        loadRequestDetails(requestNumber);
        Cookie.remove(REQUEST_NUMBER);
      }
    },
    // eslint-disable-next-line
    [requestNumber, userState.isGetUserDataLoading]
  );

  useEffect(() => {
    if (details && details.request_number !== "") {
      if (
        details.request_type === REQUEST_TYPE.UNSUBSCRIBE &&
        (details.request_status === REQUEST_STATUS.REQUESTING ||
          details.request_status === REQUEST_STATUS.QUEUED)
      ) {
        details.request_status = REQUEST_STATUS.PROCESSING;
      }

      setStatus(details.request_status as RequestStatus);
      setRequestType(details.request_type as RequestType);
    }
  }, [details]);

  useEffect(() => {
    if (isModalOpen) {
      UtilsKeyboardAccessibility.setFocus("request-details-modal");
    }
    if (isCancelAlertOpen) {
      UtilsKeyboardAccessibility.setFocus("request-details-alert");
    }
    if (isSentFilePasswordOpen) {
      UtilsKeyboardAccessibility.setFocus("send-password-alert");
    }
  }, [isModalOpen, isCancelAlertOpen, isSentFilePasswordOpen]);

  useEffect(() => {
    if (isRequestDetailsLoading) {
      return;
    }

    UtilsKeyboardAccessibility.addTabEventListener(
      "close-details-modal",
      "request-details-modal"
    );
    UtilsKeyboardAccessibility.addTabEventListener(
      "alert-cancel",
      "request-details-alert"
    );
    UtilsKeyboardAccessibility.addTabEventListener(
      "send-password-close",
      "send-password-alert"
    );

    return () => {
      UtilsKeyboardAccessibility.removeTabEventListener(
        "close-details-modal",
        "request-details-modal"
      );
      UtilsKeyboardAccessibility.removeTabEventListener(
        "alert-cancel",
        "request-details-alert"
      );
      UtilsKeyboardAccessibility.removeTabEventListener(
        "send-password-close",
        "send-password-alert"
      );
    };
  }, [isRequestDetailsLoading]);

  useEffect(() => {
    if (myDataState.serviceRequestToastError.isError) {
      setModalOpen(false);
    }
  }, [myDataState.serviceRequestToastError.isError]);
  // const { countryCode, languageTrans } = appLayoutState;
  return (
    <>
      <FocusTrapping
        isActive={isCancelAlertOpen}
        elements="button.alert-header__close__btn, #cancel, #alert-cancel"
      >
        <Alert
          onHideTestId="complete-cancel-button"
          testId="request-detail-modal"
          wrapperClassname="request-details-alert"
          show={isCancelAlertOpen}
          onHide={() => {
            setCancelAlertOpen(false);
            handleOnHideAlert();
          }}
          id="request-details-alert"
        >
          <Alert.Text>{t("PRIVACY_CANCEL_REQUEST_BODY")}</Alert.Text>
          <Alert.Footer>
            <div className="button-container">
              <Button
                id="cancel"
                testId="cancel-alert-button"
                type="secondary"
                title={t("PRIVACY_REQUEST_CONFIRMATION_BTN_CANCEL")}
                onClick={() => {
                  setCancelAlertOpen(false);
                  handleOnHideAlert();
                }}
                disabled={isCancelRequestPending}
              ></Button>
            </div>
            <div className="button-container">
              <Button
                testId="cancel-request-btn"
                title={t("PRIVACY_CANCEL_REQUEST_BTN_CANCEL_REQUEST")}
                onClick={handleOnClickConfirmCancel}
                disabled={isCancelRequestPending}
                id="alert-cancel"
              ></Button>
            </div>
          </Alert.Footer>
        </Alert>
      </FocusTrapping>

      <FocusTrapping
        isActive={isCancelRequestComplete}
        elements="button.alert-header__close__btn, #close"
      >
        <Alert
          wrapperClassname="request-details-alert"
          onHideTestId="close-cancel-req-modal"
          show={isCancelRequestComplete}
          onHide={() => {
            closCancelRequestModal();
            handleOnHideAlert();
            handleOnCompleteCancel();
          }}
        >
          <Alert.Text>{t("PRIVACY_CANCEL_REQUEST_MESSAGE_TEXT")}</Alert.Text>
          <Alert.SubText>
            {t("PRIVACY_CANCEL_REQUEST_MESSAGE_SUBTEXT")}
          </Alert.SubText>
          <Alert.Footer>
            <div className="button-container">
              <Button
                id="close"
                testId="cancel-button-req-modal"
                type="primary"
                title={t("PRIVACY_CANCEL_REQUEST_BTN_CLOSE")}
                onClick={() => {
                  closCancelRequestModal();
                  handleOnHideAlert();
                  handleOnCompleteCancel();
                }}
              ></Button>
            </div>
          </Alert.Footer>
        </Alert>
      </FocusTrapping>

      <FocusTrapping isActive={isSentFilePasswordOpen} elements="button, input">
        <Alert
          wrapperClassname="request-details-alert"
          show={isSentFilePasswordOpen}
          onHideTestId="file-password-on-hide-button"
          onHide={() => {
            // closeFilePasswordModal();
            handleOnHideAlert();
          }}
          title={t("PRIVACY_REQUEST_SENDPASSWORD_MODAL_TITLE")}
          id="send-password-alert"
        >
          {(userData.mail || !isUserEmailNotSet) && (
            <>
              <Alert.Text className="request-details-alert__pwd-text">
                {!isFilePasswordSent
                  ? t("PRIVACY_REQUEST_SENDPASSWORD_MODAL_ERROR_TITLE")
                  : t("PRIVACY_REQUEST_SENDPASSWORD_MODAL_TEXT")}
              </Alert.Text>
              <Alert.Text className="request-details-alert__pwd-email">
                {userData.mail}
              </Alert.Text>
            </>
          )}
          <Alert.SubText
            className={
              !userData.mail || isUserEmailNotSet
                ? "request-details-alert__pwd-subtext"
                : undefined
            }
          >
            {userData.mail || !isUserEmailNotSet ? (
              !isFilePasswordSent ? (
                <span id="error-status">
                  {t("PRIVACY_REQUEST_SENDPASSWORD_MODAL_ERROR")}
                </span>
              ) : (
                t("PRIVACY_REQUEST_SENDPASSWORD_MODAL_TEXT2")
              )
            ) : (
              t("PRIVACY_REQUEST_DETAILS_TEXT_NO_EMAIL")
            )}
          </Alert.SubText>
          <Alert.Footer>
            <div className="button-container">
              <Button
                testId="close-filepassword-modal"
                type="primary"
                title={t("PRIVACY_CANCEL_REQUEST_BTN_CLOSE")}
                onClick={() => {
                  // closeFilePasswordModal();
                  handleOnHideAlert();
                }}
                id="send-password-close"
              ></Button>
            </div>
          </Alert.Footer>
        </Alert>
      </FocusTrapping>

      <FocusTrapping
        isActive={isModalOpen}
        elements={getFocusTrappingElements()}
      >
        <Modal
          show={isModalOpen}
          className="request-detail-modal"
          wrapperClassName="request-details"
        >
          {isRequestDetailsLoading && (
            <Lottie
              className="request-details__loading"
              animationData={Loading}
              loop={true}
              style={{ width: 60, height: 60 }}
            />
          )}

          {!isRequestDetailsLoading && (
            <>
              <Modal.Header
                headertestid={headerTestid}
                onHide={handleOnClickHideModal}
              >
                <Modal.Title ttsButtonId="request-details-tts">
                  {t(modalTitle)}
                </Modal.Title>
              </Modal.Header>

              {details && details.request_number !== "" && (
                <Modal.Body>
                  <div className="request-details__headings">
                    <Steps status={status as RequestStatus} />
                    <TitleSection
                      details={details}
                      status={status as RequestStatus}
                      requestType={requestType as RequestType}
                      onClickCancel={() => handleOnClickCancel()}
                      onClickTransferPassword={() =>
                        handleOnClickTransferPassword()
                      }
                    />
                  </div>
                  <div className="request-details__services-list">
                    {details.request_type === RequestType.DOWNLOAD &&
                    details.request_status === RequestStatus.COMPLETED ? (
                      <DownloadCompletedServices details={details} />
                    ) : (
                      <Container>
                        <Container>
                          <Row>
                            {details.request_status ===
                              RequestStatus.REQUESTING ||
                            details.request_status === RequestStatus.QUEUED ||
                            details.request_status ===
                              RequestStatus.PROCESSING ||
                            details.request_status ===
                              RequestStatus.COMPLETED ||
                            details.request_status === RequestStatus.DENIED ? (
                              <>
                                <Col md={6}>
                                  <Typography variant="body2" className="title">
                                    {t(
                                      "PRIVACY_MYDATA_REQ_DETAIL_BODY_DATE_SUBMITTED"
                                    )}
                                  </Typography>
                                </Col>
                                <Col md={6}>
                                  <Typography variant="body2" className="value">
                                    {UtilsDateTime.convertDateString(
                                      details.user_request_date
                                    )}
                                  </Typography>
                                </Col>
                                <div className="grid-spacer"></div>
                              </>
                            ) : details.request_status ===
                              RequestStatus.EXPIRED ? (
                              UtilsDateTime.getExpiredDate(details) ? (
                                <>
                                  <Col md={6}>
                                    <Typography
                                      variant="body2"
                                      className="title"
                                    >
                                      {t(
                                        "PRIVACY_MYDATA_REQ_DETAIL_BODY_EXP_DATE"
                                      )}
                                    </Typography>
                                  </Col>
                                  <Col md={6}>
                                    <Typography
                                      variant="body2"
                                      className="value"
                                    >
                                      {UtilsDateTime.convertDateString(
                                        details.user_request_date
                                      )}
                                    </Typography>
                                  </Col>
                                  <div className="grid-spacer"></div>
                                </>
                              ) : (
                                <>
                                  <Col md={6}>
                                    <Typography
                                      variant="body2"
                                      className="title"
                                    >
                                      {t(
                                        "PRIVACY_MYDATA_REQ_DETAIL_BODY_DATE_SUBMITTED"
                                      )}
                                    </Typography>
                                  </Col>
                                  <Col md={6}>
                                    <Typography
                                      variant="body2"
                                      className="value"
                                    >
                                      {UtilsDateTime.convertDateString(
                                        details.user_request_date
                                      )}
                                    </Typography>
                                  </Col>
                                  <div className="grid-spacer"></div>
                                </>
                              )
                            ) : (
                              details.request_status ===
                                RequestStatus.CANCELED && (
                                <>
                                  <Col md={6}>
                                    <Typography
                                      variant="body2"
                                      className="title"
                                    >
                                      {t(
                                        "PRIVACY_MYDATA_REQ_DETAIL_BODY_CANCEL_DATE"
                                      )}
                                    </Typography>
                                  </Col>
                                  <Col md={6}>
                                    <Typography
                                      variant="body2"
                                      className="value"
                                    >
                                      {details.complete_date &&
                                        UtilsDateTime.convertDateString(
                                          details.complete_date
                                        )}
                                    </Typography>
                                  </Col>
                                  <div
                                    data-testid="cancel-req-modal"
                                    className="grid-spacer"
                                  ></div>
                                </>
                              )
                            )}
                            <Col md={6} sm={10}>
                              <Typography variant="body2" className="title">
                                {t("PRIVACY_MYDATA_BODY_SERVICES")}
                              </Typography>
                            </Col>
                            <Col md={6} sm={2}>
                              <Typography variant="body2" className="value">
                                {details.sub_request_count}
                              </Typography>
                            </Col>
                          </Row>
                          <Row>
                            <Col>
                              <hr />
                            </Col>
                          </Row>
                          <Row className="services">
                            {details.sub_request_list.map((request) => {
                              return (
                                <Col
                                  md={6}
                                  sm={12}
                                  key={request.sub_request_number}
                                >
                                  <div className="request-details__services-list__service">
                                    <Image
                                      image={request.service_icon}
                                      width={40}
                                      height={40}
                                    />
                                    <Typography
                                      variant="body2"
                                      className="service-name"
                                    >
                                      {request.service_name}
                                    </Typography>
                                  </div>
                                </Col>
                              );
                            })}
                          </Row>
                        </Container>
                      </Container>
                    )}
                  </div>
                </Modal.Body>
              )}

              <Modal.Footer>
                <Button
                  variant="solid"
                  type="primary"
                  testId="request-detail-hide-button"
                  title={t("PRIVACY_CANCEL_REQUEST_BTN_CLOSE")}
                  className="btn-close"
                  onClick={handleOnClickHideModal}
                  id="close-details-modal"
                />
              </Modal.Footer>
            </>
          )}
        </Modal>
      </FocusTrapping>
    </>
  );
};

interface CompletedServicesProps {
  details: RequestDetailsEntity;
}

export const DownloadCompletedServices: React.FC<CompletedServicesProps> = (
  props
) => {
  const { t } = useTranslation();
  const [hasDownloadableFile, setHasDownloadableFile] = useState(false);

  const { details } = props;

  useEffect(() => {
    if (UtilsDateTime.getExpiredDate(details)) {
      setHasDownloadableFile(true);
    }
  }, [details]);

  return (
    <Container>
      <Container>
        <Row>
          {UtilsDateTime.getExpiredDate(details) ? (
            <>
              <Col md={6}>
                <Typography variant="body2" className="title">
                  {t("PRIVACY_MYDATA_REQ_DETAIL_BODY_EXP_DATE")}
                </Typography>
              </Col>
              <Col md={6}>
                <Typography variant="body2" className="value">
                  {UtilsDateTime.convertDateString(
                    UtilsDateTime.getExpiredDate(details)
                  )}
                </Typography>
              </Col>
              <div className="grid-spacer"></div>
            </>
          ) : (
            <>
              <Col md={6}>
                <Typography variant="body2" className="title">
                  {t("PRIVACY_MYDATA_REQ_DETAIL_BODY_DATE_SUBMITTED")}
                </Typography>
              </Col>
              <Col md={6}>
                <Typography variant="body2" className="value">
                  {UtilsDateTime.convertDateString(
                    details.user_request_date || Date.toString()
                  )}
                </Typography>
              </Col>
              <div className="grid-spacer"></div>
            </>
          )}
          <Col md={6} sm={10}>
            <Typography variant="body2" className="title">
              {t("PRIVACY_MYDATA_BODY_SERVICES")}
            </Typography>
          </Col>
          <Col md={6} sm={2}>
            <Typography variant="body2" className="value">
              {details.sub_request_count}
            </Typography>
          </Col>
        </Row>
        <Row>
          <Col>
            <hr />
          </Col>
        </Row>
        <Row className="services">
          {details.sub_request_list.map((request) => {
            return (
              <Fragment key={request.service_code}>
                <Col md={6} sm={12}>
                  <div className="request-details__services-list__service">
                    <Image
                      image={request.service_icon}
                      width={40}
                      height={40}
                    />
                    <Typography variant="body2" className="service-name">
                      {request.service_name}
                    </Typography>
                  </div>
                </Col>
                {request.attach_file?.length === 0 && hasDownloadableFile ? (
                  <Col md={6} sm={12} className="no-data">
                    <div className="request-details__services-list__files">
                      <Typography variant="body3" weight="w400">
                        {t("PRIVACY_MYDATA_REQ_DETAIL_BODY_EXP_EMPTY")}
                      </Typography>
                    </div>
                  </Col>
                ) : (
                  <Col md={6} sm={12}>
                    {request.attach_file?.map((file, index) => (
                      <div
                        className="request-details__services-list__files"
                        key={file.file_name}
                      >
                        <div className="file-label">
                          <Typography variant="body2" weight="w700">
                            File {("0" + (index + 1)).slice(-2)}
                          </Typography>
                        </div>
                        <div className="file-size">
                          <Typography variant="body2" weight="w400">
                            {UtilsConverter.convertSize(file.file_size)}
                          </Typography>
                        </div>
                        <div className="download-icon">
                          <a
                            download
                            data-testid="trigger-download"
                            href={file.url}
                            onClick={() =>
                              GoogleAnalytics.triggerEvent(
                                EVENT_CATEGORY_REQUEST_DETAILS,
                                EVENT_ACTION_DETAILS_DOWNLOAD_FILE
                              )
                            }
                          >
                            <Svg
                              icon={SVG_ICONS.MYDATA_DOWNLOAD_BTN}
                              width={24}
                              height={24}
                            />
                          </a>
                        </div>
                      </div>
                    ))}
                  </Col>
                )}
              </Fragment>
            );
          })}
        </Row>
      </Container>
    </Container>
  );
};
