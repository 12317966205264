import React, { useEffect, useState } from "react";

import { useSelector } from "react-redux";
import { ApplicationState } from "../../../store";
import { LayoutState } from "../../../store/reducers/layout-reducer";
import FocusTrapping from "../../__hoc/FocusTrapping";
import { Button } from "../_controls";
import Alert from "../Alert/Alert";
import { Svg, SVG_ICONS } from "../Svg/Svg";
import { Typography } from "../Typography/Typography";
import "./dialog.scss";
import { Cookie } from "../../../services/storage";
import { REDIRECT_URI, REQUEST_NUMBER } from "../../../constants/storage";
import { ROUTES } from "../../../configs/routes";

interface Props {
  id?: string;
  title: string;
  body: string;
  buttonTitle?: string;
  show: boolean;
  isIdleDialog?: boolean;
  handleClose: () => void;
}

export const Dialog: React.FC<Props> = (props) => {
  const {
    id,
    title,
    body,
    show = false,
    buttonTitle = "Ok",
    isIdleDialog = false,
    handleClose,
  } = props;
  const { isHighContrast } = useSelector<ApplicationState, LayoutState>(
    (state) => state.layout
  );

  const [isOpen, setIsOpen] = useState<boolean>(show);
  const handleOnClose = () => {
    handleClose();
    setIsOpen(false);
  };

  const cleanupFunctionForIdleUser = () => {
    window.history.replaceState(null, "", ROUTES.ROOT);
    Cookie.remove(REQUEST_NUMBER);
    Cookie.remove(REDIRECT_URI);
    localStorage.removeItem("SHOW_TIMEOUT_DIALOG");
  };

  useEffect(() => {
    if (show) {
      setIsOpen(true);
      if (isIdleDialog) {
        cleanupFunctionForIdleUser();
      }
    }
  }, [isOpen, show]);

  const closeComponent = (viewType: string) => (
    <button
      className={viewType}
      data-testid="close-btn"
      onClick={handleOnClose}
    >
      <Svg
        icon={
          !isHighContrast
            ? SVG_ICONS.DIALOG_CLOSE_BUTTON_ICON
            : SVG_ICONS.DIALOG_CLOSE_BUTTON_ICON_HC
        }
        width={18}
        height={18}
      />
    </button>
  );
  return (
    <>
      <FocusTrapping
        isActive={true}
        elements="svg, button, div.title2 , div.modal-body"
      >
        <div className="dialog" id={id}>
          <Alert
            show={isOpen}
            title={title}
            customClose={closeComponent("dialog__close")}
            isDialogModal={true}
          >
            <div className="dialog__text">
              <Typography variant="body2">{body}</Typography>
            </div>

            <Alert.Footer>
              <div className="button-container">
                <Button
                  className="dialog-ok-button"
                  testId="dialog-ok-button"
                  title={buttonTitle}
                  onClick={handleOnClose}
                  id="dialog-ok-button"
                ></Button>
              </div>
            </Alert.Footer>
          </Alert>
        </div>
      </FocusTrapping>
    </>
  );
};
