import reactGA from "react-ga4";
import { GA4_ENV, VALID_ROUTES } from "../constants/analytics";
import { ALLOW_PERFORMANCE_COOKIES } from "../constants/storage";

/**
 * A singleton class that can be accessed globally.
 */
export default class GoogleAnalytics {
  public static isInitialized: boolean = false;

  // Make the constructor private to block instantiation outside of the class.
  private constructor() {}

  public static initialize(host: string): void {
    const cookieExpiry = 31536000; // override default 2 yrs cookie expiry to just 1 yr
    const isGoogleCookiesAllowed =
      localStorage.getItem(ALLOW_PERFORMANCE_COOKIES) === "true";

    if (!isGoogleCookiesAllowed || this.isInitialized) return;

    reactGA.initialize(GA4_ENV[host], {
      gaOptions: {
        cookieExpires: cookieExpiry,
      },
      gtagOptions: {
        send_page_view: false,
      },
    });

    this.isInitialized = true;
  }

  public static sendPageView(page: string): void {
    if (!this.isInitialized) return;

    // Catch successful initial login
    if (window.location.search === "?success=1") {
      reactGA.send({ hitType: "pageview", page: "/login-success" });
    }

    if (VALID_ROUTES.includes(page)) {
      reactGA.send({ hitType: "pageview", page, title: page });
    }
  }

  public static triggerEvent(category: string, action: string): void {
    if (!this.isInitialized) return;

    reactGA.event({ category, action });
  }

  public static disableTracking(): void {
    delete window.GoogleAnalyticsObject;
    delete window.ga;
    delete window.gaData;
    delete window.gaGlobal;
    delete window.gaplugins;
    delete window.google_tag_data;

    this.isInitialized = false;
  }
}
