import React, { useRef, useState } from "react";

import Tabs from "../../../../components/__common/_controls/Tab/Tab";
import {
  filterTabData,
  serviceGroupData,
} from "../../../../constants/personalInfoConstants";
import { REQUEST_STATUS } from "../../../../constants/status";
import {
  HISTORY_REQUEST_TYPE,
  MyDataHistoryReqBody,
} from "../../../../types/history";

import { useTranslation } from "react-i18next";
interface Props {
  loadMyDataHistory: (param: any) => void;
  historyReqBody: MyDataHistoryReqBody;
}

export const HistoryFilters: React.FC<Props> = (props) => {
  const { t } = useTranslation();
  const { loadMyDataHistory, historyReqBody } = props;

  const [isDown, setisDown] = useState<boolean>(false);
  const [startX, setstartX] = useState<number>(0);
  const [scrollLeft, setscrollLeft] = useState<number>(0);

  const [isDownStatus, setisDownStatus] = useState<boolean>(false);
  const [startXStatus, setstartXStatus] = useState<number>(0);
  const [scrollLeftStatus, setscrollLeftStatus] = useState<number>(0);

  const handleRequestTypeOnChange = (value: HISTORY_REQUEST_TYPE) => {
    loadMyDataHistory({
      ...historyReqBody,
      request_type: value,
    });
  };

  const handleRequestStatusOnChange = (value?: REQUEST_STATUS) => {
    loadMyDataHistory({
      ...historyReqBody,
      request_status: value,
    });
  };

  const onMouseDown = (e: any) => {
    setisDown(true);
    setstartX(e.pageX - requestTypeRef!.current!.offsetLeft);
    setscrollLeft(requestTypeRef!.current!.scrollLeft);
  };

  const onMouseUp = (e: any) => {
    setisDown(false);
    e.preventDefault();
    e.stopPropagation();
  };

  const onMouseLeave = (e: any) => {
    e.preventDefault();
    setisDown(false);
  };

  const onMouseMove = (e: any) => {
    if (!isDown) return;
    e.preventDefault();
    const x: number = e.pageX - requestTypeRef!.current!.offsetLeft;
    const walk = x - startX;
    requestTypeRef!.current!.scrollLeft = scrollLeft - walk;
  };

  const onMouseDownStatus = (e: any) => {
    setisDownStatus(true);
    setstartXStatus(e.pageX - requestStatusRef!.current!.offsetLeft);
    setscrollLeftStatus(requestStatusRef!.current!.scrollLeft);
  };

  const onMouseUpStatus = (e: any) => {
    setisDownStatus(false);
    e.preventDefault();
    e.stopPropagation();
  };

  const onMouseLeaveStatus = (e: any) => {
    e.preventDefault();
    setisDownStatus(false);
  };

  const onMouseMoveStatus = (e: any) => {
    if (!isDownStatus) return;
    e.preventDefault();
    const x: number = e.pageX - requestStatusRef!.current!.offsetLeft;
    const walk = x - startXStatus;
    requestStatusRef!.current!.scrollLeft = scrollLeftStatus - walk;
  };

  const requestTypeRef = useRef<HTMLDivElement>(null);
  const requestStatusRef = useRef<HTMLDivElement>(null);
  return (
    <div className="history-filter-container">
      <div className="mydata-personal-info__filter-section">
        <div
          ref={requestTypeRef}
          data-testid="request-type"
          className="mydata-personal-info__service-group"
          onMouseDown={onMouseDown}
          onMouseLeave={onMouseLeave}
          onMouseUp={onMouseUp}
          onMouseMove={onMouseMove}
        >
          <Tabs className="drag-content">
            {serviceGroupData.map((e: any) => (
              <Tabs.Tab
                key={e.id}
                radioTestId="request-type-input"
                id={e.id}
                title={t(e.title)}
                name={t(e.name)}
                checked={historyReqBody.request_type === e.value}
                onChange={() => handleRequestTypeOnChange(e.value)}
                buttonTestid="request-type-button"
              />
            ))}
          </Tabs>
        </div>
      </div>
      <div className="mydata-personal-info__filter-status">
        <div
          className="mydata-personal-info__filter-group"
          ref={requestStatusRef}
          data-testid="request-status"
          onMouseDown={onMouseDownStatus}
          onMouseLeave={onMouseLeaveStatus}
          onMouseUp={onMouseUpStatus}
          onMouseMove={onMouseMoveStatus}
        >
          <Tabs>
            {filterTabData.map((e) => (
              <Tabs.Tab
                key={e.id}
                id={e.id}
                title={t(e.title)}
                type={t(e.type as any)}
                name={t(e.name)}
                buttonTestid="request-status-button"
                checked={historyReqBody.request_status === e.value}
                onChange={() => handleRequestStatusOnChange(e.value)}
              />
            ))}
          </Tabs>
        </div>
      </div>
    </div>
  );
};
