import { combineReducers, createStore } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
// import { composeWithDevTools } from "redux-devtools-extension/developmentOnly";
import { layoutReducer, LayoutState } from "./reducers/layout-reducer";
import {
  loginOptionReducer,
  LoginOptionState,
} from "./reducers/login-option-reducer";
import myDataReducer, { MyDataState } from "./reducers/mydata-reducer";
import privacyReducer, { PrivacyState } from "./reducers/privacy-reducer";
import serviceReducer, { ServicesState } from "./reducers/services-reducer";
import systemReducer, { SystemState } from "./reducers/site-reducer";
import userReducer, { UserState } from "./reducers/user-reducer";
import storage from "redux-persist/lib/storage";
import { persistReducer, persistStore } from "redux-persist";

export interface CustomAction {
  type: string;
  data?: any;
}

export interface ApplicationState {
  user: UserState;
  services: ServicesState;
  myData: MyDataState;
  layout: LayoutState;
  privacy: PrivacyState;
  loginOption: LoginOptionState;
  system: SystemState;
}

const persistedUserReducer = persistReducer(
  {
    key: "user",
    storage,
  },
  userReducer
);

const reducer = combineReducers({
  user: persistedUserReducer,
  services: serviceReducer,
  myData: myDataReducer,
  layout: layoutReducer,
  privacy: privacyReducer,
  loginOption: loginOptionReducer,
  system: systemReducer,
});

export const store = createStore(reducer, composeWithDevTools());
// Persistor
export const persistor = persistStore(store);
