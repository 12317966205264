import React, { useEffect } from "react";
import { useState } from "react";
import { Dialog } from "../dialog";
import { useTranslation } from "react-i18next";
import { UtilsUser } from "../../../../utils/UtilsUser";
import { ROUTES } from "../../../../configs/routes";

interface Props {
  isTimeOutDialogOpen: boolean;
}
export const IdleTimeoutDialog: React.FC<Props> = (props) => {
  const { isTimeOutDialogOpen } = props;
  const { t } = useTranslation();
  const [showTimeoutDialog, setSshowTimeoutDialog] = useState<boolean>(false);

  useEffect(() => {
    if (isTimeOutDialogOpen && !UtilsUser.isUserLoggedIn()) {
      setSshowTimeoutDialog(true);
      window.history.replaceState(null, "", ROUTES.ROOT);
    }
  }, [isTimeOutDialogOpen, UtilsUser.isUserLoggedIn()]);
  return (
    <Dialog
      show={showTimeoutDialog}
      title={t("PRIVACY_SESSION_TIMEOUT_TITLE")}
      body={`${t("PRIVACY_SESSION_TIMEOUT_BODY1")} ${"\n"} ${t(
        "PRIVACY_SESSION_TIMEOUT_BODY2"
      )} `}
      handleClose={() => {
        setSshowTimeoutDialog(false);
        localStorage.removeItem("SHOW_TIMEOUT_DIALOG");
      }}
      buttonTitle={t("PRIVACY_DIALOG_OK")}
      isIdleDialog={true}
    />
  );
};
