import { CustomAction } from "..";
import { SITE_SETTINGS_TYPE } from "../actions-types";

export type SystemState = {
  isLoading: boolean;
  sysProps: {
    GEO_IP: string;
    SA_COUNTRY: string;
    SA_AGE: string;
    SITE_COUNTRY: string;
    SITE_LANGUAGE: string;
    BUILD_VERSION: string;
    BUILD_TIMESTAMP: string;
  };
};

const initialState: SystemState = {
  // Prevents other API's to be called.
  // This way, the system-properties API will be called first.
  isLoading: false,
  sysProps: {
    GEO_IP: "",
    SA_COUNTRY: "",
    SA_AGE: "",
    SITE_COUNTRY: "",
    SITE_LANGUAGE: "",
    BUILD_VERSION: "",
    BUILD_TIMESTAMP: "",
  },
};

export default function systemReducer(
  state = initialState,
  action: CustomAction
) {
  const { type, data } = action;

  switch (type) {
    case SITE_SETTINGS_TYPE.SET_LOADING:
      return {
        ...state,
        isLoading: data,
      };
    case SITE_SETTINGS_TYPE.SET_USER_SYSTEM_PROPERTIES:
      return {
        ...state,
        sysProps: data,
      };
    default:
      return state;
  }
}
