import { REDIRECT, REDIRECT_URI, REQUEST_NUMBER } from "../constants/storage";
import { Cookie } from "../services/storage";

/**
 * Get the URL where the user is located prior to logging in.
 *
 * This function also handles the redirect URL wherein the user clicks a link
 * in a request email (opt out, deletion, and download).
 */
export const getRedirectUrl = () => {
  const param = sessionStorage.getItem(REDIRECT);

  if (!param) {
    return "/";
  }

  let redirect = param; // To avoid mutating the original redirect URL
  const params = param.split("/");
  const regex = /SP|SPDL-(DEV|STG|PRE|[0-9A-Z]*)/g;

  if (param.match(regex)) {
    params.pop();
    redirect = params.join("/");
    Cookie.set(REDIRECT_URI, `${redirect}/${Cookie.get(REQUEST_NUMBER)}`);
  }

  // return redirect URL - NOTE: success?=1 query params is not yet been handled by the current implementation
  return redirect;
};
