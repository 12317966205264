import { URLS } from "../constants/urls";

export const ROUTES = {
  ROOT: URLS.ROOT,
  HOME: URLS.HOME,
  LOGIN: URLS.LOGIN,
  TYPOGRAPHY: URLS.TYPOGRAPHY,
  MODAL: URLS.MODALS,
  BUTTONS: URLS.BUTTONS,
  FORMS: URLS.FORMS,
  ABOUT_OVERVIEW: URLS.ABOUT_OVERVIEW,
  ABOUT_GRPS: URLS.ABOUT_GRPS,
  MYDATA: URLS.MYDATA,
  MYDATA_HISTORY: URLS.MYDATA_HISTORY,
  SUMMARY_REQUEST_DETAILS: `${URLS.MYDATA}/${URLS.PARAMS.REQUEST_NUMBER}`,
  MYDATA_HISTORY_REQUEST_DETAILS: `${URLS.MYDATA_HISTORY}/${URLS.PARAMS.REQUEST_NUMBER}`,
  OLD_REQUEST_DETAILS: `${URLS.OLD_MYDATA}${URLS.OLD_REQUEST_DETAILS}/${URLS.PARAMS.REQUEST_NUMBER}`,
  OLD_DOWNLOAD_HISTORY: `${URLS.OLD_MYDATA}${URLS.OLD_DOWNLOAD_HISTORY}`,
  OLD_DELETE_HISTORY: `${URLS.OLD_MYDATA}${URLS.OLD_DELETE_HISTORY}`,
  OLD_OPTOUT_HISTORY: `${URLS.OLD_MYDATA}${URLS.OLD_OPTOUT_HISTORY}`,
  OLD_DOWNLOAD_REQUEST: `${URLS.OLD_MYDATA}${URLS.OLD_REQUEST_DOWNLOAD}`,
  OLD_DELETE_REQUEST: `${URLS.OLD_MYDATA}${URLS.OLD_REQUEST_DELETE}`,
  OLD_OPTOUT_REQUEST: `${URLS.OLD_MYDATA}${URLS.OLD_REQUEST_OPTOUT}`,
  OLD_ABOUT_GRPS: URLS.OLD_ABOUT_GRPS,
  ERROR_400: URLS.ERROR_400,
  ERROR_403: URLS.ERROR_403,
  ERROR_404: URLS.ERROR_404,
  ERROR_500: URLS.ERROR_500,
  ERROR_502: URLS.ERROR_502,
  ERROR_503: URLS.ERROR_503,
  ERROR_IE: URLS.ERROR_IE,
  ERROR_MAINTENANCE: URLS.ERROR_MAINTENANCE,
  PRIVACY_POLICY: URLS.PRIVACY_POLICY,
  POLICY_OVERVIEW: URLS.POLICY_OVERVIEW,
  COOKIE_POLICY: URLS.COOKIE_POLICY,
  OLD_CONSIGNMENT_WITH_KR: URLS.OLD_CONSIGNMENT_WITH_KR,
  OLD_CONSIGNMENT: URLS.OLD_CONSIGNMENT,
  BDP_CONSIGNMENT: URLS.BDP_CONSIGNMENT,
  ANALYTICS_CONSIGNMENT: URLS.ANALYTICS_CONSIGNMENT,
  DIAGMON_CONSIGNMENT: URLS.DIAGMON_CONSIGNMENT,
  ADSSO_LOGOUT: URLS.ADSSO_LOGOUT,
  DIGITAL_LEGACY_AUTH: URLS.DIGITAL_LEGACY_AUTH,
};

export const AUTHENTICATED_ROUTES = [
  ROUTES.MYDATA,
  ROUTES.MYDATA_HISTORY,
  ROUTES.SUMMARY_REQUEST_DETAILS,
  ROUTES.OLD_REQUEST_DETAILS,
  ROUTES.OLD_DOWNLOAD_HISTORY,
  ROUTES.OLD_DELETE_HISTORY,
  ROUTES.OLD_OPTOUT_HISTORY,
  ROUTES.OLD_DOWNLOAD_REQUEST,
  ROUTES.OLD_DELETE_REQUEST,
  ROUTES.OLD_OPTOUT_REQUEST,
];

export const COMMON_NON_AUTH_ROUTES = [
  ROUTES.ABOUT_OVERVIEW,
  ROUTES.ABOUT_GRPS,
  ROUTES.PRIVACY_POLICY,
  ROUTES.POLICY_OVERVIEW,
  ROUTES.COOKIE_POLICY,
];
