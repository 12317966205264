import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import "./GlobalRightsResponse.scss";
import { Typography } from "../../../components/__common/Typography/Typography";
import { Col, Container, Row } from "../../../components/Grid/Grid";
import { Svg, SVG_ICONS } from "../../../components/__common/Svg/Svg";
import { GlobalSiteLinkPopup } from "../../../components/GlobalSiteLinkPopup/GlobalSiteLinkPopup";
import { LinkButton, TTSButton } from "../../../components/__common/_controls";
import StaticModal from "../../../components/__common/StaticModal/StaticModal";
import { useWindowSize } from "../../../hooks/window-hooks";
import { Trans } from "react-i18next";
import { ROUTES } from "../../../configs/routes";
import { ApplicationState } from "../../../store";
import { MyDataState } from "../../../store/reducers/mydata-reducer";
import {
  GpsRegion,
  GPS_REGION_CODE,
  GpsCountry,
} from "../../../types/gps-region";
import GPSMap from "./GPSMap";

import {
  EVENT_CATEGORY_GPS,
  EVENT_GPS_ACTION_LIST,
  EVENT_GPS_ACTION_SDAP_SITE,
} from "../../../constants/analytics";
import { GpsRegions } from "../../../data/gps-regions";
import {
  CBType,
  Checkbox,
} from "../../../components/__common/_controls/Checkbox/Checkbox";
import Modal from "../../../components/__common/Modal/Modal";
import Accordion from "../../../components/__common/Accordion/Accordion";
import GPSMapMobile from "./GPSMapMobile";
import { UtilsKeyboardAccessibility } from "../../../utils/UtilsKeyboardAccessibility";
import GoogleAnalytics from "../../../utils/GoogleAnalytics";

export const GlobalRightsResponse: React.FC = () => {
  const { t } = useTranslation();
  const { isMobile } = useWindowSize();
  const [isToastOpen, setToastOpen] = useState(true);
  const appMyDataState = useSelector<ApplicationState, MyDataState>(
    (state) => state.myData
  );
  const { isGlobalSiteLinkOpen } = appMyDataState;
  const [selectedRegion, setSelectedRegion] = useState<GpsRegion | undefined>();
  const [selectedModalRegion, setSelectedModalRegion] = useState<
    GpsRegion | undefined
  >();

  const [isGpsListModalOpen, setIsGpsListModalOpen] = useState<boolean>(false);

  const handleRegionClick = (regionCode: GPS_REGION_CODE) => {
    const selectedRegionFromMap = GpsRegions.filter(
      (region) => region.code === regionCode
    )[0];
    selectedRegionFromMap && setSelectedRegion(selectedRegionFromMap);
  };

  const handleGAevent = (countryCode: string) => {
    GoogleAnalytics.triggerEvent(
      EVENT_CATEGORY_GPS,
      `${EVENT_GPS_ACTION_LIST}-${countryCode}`
    );
  };

  const handleUserInteraction = (country: GpsCountry) => {
    handleGAevent(country.code);
    window.open(country.link, "_blank");
  };

  useEffect(() => {
    if (isGpsListModalOpen) {
      UtilsKeyboardAccessibility.setFocus("global-rights-modal");
    }
  }, [isGpsListModalOpen]);

  useEffect(() => {
    const lastRegion = GpsRegions[GpsRegions.length - 1];

    if (
      selectedModalRegion !== undefined &&
      selectedModalRegion.name === lastRegion.name
    ) {
      UtilsKeyboardAccessibility.addTabEventListener(
        "last-language",
        "global-rights-modal"
      );
    } else if (selectedModalRegion?.name !== lastRegion.name) {
      UtilsKeyboardAccessibility.addTabEventListener(
        "last-region",
        "global-rights-modal"
      );
    }

    return () => {
      UtilsKeyboardAccessibility.removeTabEventListener(
        "last-language",
        "global-rights-modal"
      );
      UtilsKeyboardAccessibility.removeTabEventListener(
        "last-region",
        "global-rights-modal"
      );
    };
  }, [selectedModalRegion]);

  useEffect(() => {
    if (selectedRegion && isMobile) {
      document
        .getElementById("country-list")
        ?.scrollIntoView({ behavior: "smooth" });
    }
  }, [selectedRegion]);

  return (
    <div className="global-rights">
      <Container>
        <div className="global-rights__title-section">
          <Typography
            variant="h2"
            className="global-rights__title-section__title sharp-sans"
          >
            {t("PRIVACY_GRRS_PAGE_TITLE")}
            <TTSButton
              value={
                t("PRIVACY_GRRS_PAGE_TITLE") +
                ". " +
                t("PRIVACY_GRRS_PAGE_DESC")
              }
            />
          </Typography>
          <Typography
            variant="body1"
            weight="w400"
            className="global-rights__title-section__sub-title"
          >
            {t("PRIVACY_GRRS_PAGE_DESC")}
          </Typography>
        </div>
      </Container>
      {isToastOpen && isGlobalSiteLinkOpen && (
        <div>
          <GlobalSiteLinkPopup
            testId="global-site-link-popup"
            onHide={() => setToastOpen(false)}
          />
        </div>
      )}
      <div className="global-rights__map-container">
        <Container>
          <div className="global-rights__map-container__title">
            <Typography
              variant="body2"
              className="global-rights__map-container__title__sub"
            >
              <Trans
                i18nKey="PRIVACY_GRRS_INTEGRATED_SITE_TEXT2"
                components={{
                  a: (
                    // eslint-disable-next-line
                    <a
                      href={ROUTES.ROOT}
                      data-testid="gps-action-sdap"
                      onClick={() => {
                        GoogleAnalytics.triggerEvent(
                          EVENT_CATEGORY_GPS,
                          EVENT_GPS_ACTION_SDAP_SITE
                        );
                      }}
                    />
                  ),
                }}
              />
            </Typography>
            <Typography
              variant="body2"
              className="global-rights__map-container__title__sub"
            >
              <Trans
                i18nKey="PRIVACY_GRRS_INTEGRATED_SITE_TEXT3"
                components={{
                  a: (
                    // eslint-disable-next-line
                    <a
                      href={window.location.href}
                      data-testid="anchor-gps-modal"
                      onClick={(e) => {
                        e.preventDefault();
                        setIsGpsListModalOpen(true);
                      }}
                    />
                  ),
                }}
              />
            </Typography>
          </div>
          {!selectedRegion ? (
            !isMobile ? (
              <Container className="global-rights__selected-region">
                <Row>
                  <Col lg={12} sm={12}>
                    <div className="svg global-rights__map-container__map">
                      <GPSMap onRegionClick={handleRegionClick} />
                    </div>
                  </Col>
                </Row>
              </Container>
            ) : (
              <div className="gpsMapMobile">
                <GPSMapMobile onRegionClick={handleRegionClick} />
              </div>
            )
          ) : (
            <Container className="global-rights__selected-region">
              <Row>
                <Col lg={6} sm={12}>
                  <div className="svg global-rights__map-container__map">
                    <LinkButton
                      title={selectedRegion.name}
                      to="#"
                      className="region-title"
                      iconPosition="left"
                      testId="region-link-btn"
                      icon={SVG_ICONS.ARROW_RIGHT}
                      underlined={false}
                      size="lg"
                      iconSize={24}
                      onClick={() => setSelectedRegion(undefined)}
                    />
                    {selectedRegion.image && (
                      <Svg
                        icon={selectedRegion.image}
                        className="svg global-rights__map-container__map__selected"
                      />
                    )}
                  </div>
                </Col>
                <Col lg={6} sm={12}>
                  <StaticModal show autoHeight id="country-list">
                    <StaticModal.Header
                      onHide={() => setSelectedRegion(undefined)}
                    >
                      <StaticModal.Title>
                        {t("PRIVACY_GRRS_SELECT_COUNTRY")}
                      </StaticModal.Title>
                    </StaticModal.Header>
                    <StaticModal.Body>
                      <div className="global-rights__link-container">
                        <Container>
                          <Row>
                            {selectedRegion.countries.map((country) => (
                              <Col
                                md={6}
                                sm={12}
                                className="global-rights__link-container__checkbox"
                                key={country.code}
                              >
                                <Checkbox
                                  key={country.code}
                                  title={country.name}
                                  checkboxTestid="region-checkbox"
                                  name="checkbox-withoutSelectBg"
                                  type={CBType.withoutSelectBg}
                                  onClick={() => handleUserInteraction(country)}
                                  onKeyPress={() =>
                                    handleUserInteraction(country)
                                  }
                                  svgIcon={SVG_ICONS.ARROW_RIGHT}
                                />
                              </Col>
                            ))}
                          </Row>
                        </Container>
                      </div>
                    </StaticModal.Body>
                  </StaticModal>
                </Col>
              </Row>
            </Container>
          )}
        </Container>
      </div>
      {/* <Container className="global-rights__countries">
        <Container>
          <Row>
            {GrrsSiteRegions.map((grrs) => (
              <Col lg={6} md={6} sm={12} key={grrs.region}>
                <div className="global-rights__countries__item">
                  <div className="global-rights__countries__item__region">
                    <Typography variant="body1" className="sharp-sans">
                      {grrs.region}
                    </Typography>
                  </div>
                  <div className="global-rights__countries__item__list">
                    {grrs.countries.map((country) => {
                      return (
                        <div
                          className="global-rights__countries__item__list__container"
                          key={country.code}
                        >
                          <div
                            className={classNames(
                              "global-rights__countries__item__list__pin",
                              {
                                USA: country.code === "USA",
                                China: country.code === "CHN",
                                EU: country.code === "EU",
                                Türkiye: country.code === "TUR",
                                Brazil: country.code === "BRA",
                              }
                            )}
                          />
                          <LinkButton
                            key={country.code}
                            title={country.name}
                            to="#"
                            icon={SVG_ICONS.GREATE_THAN_LRG}
                            iconPosition="right"
                            size="lg"
                            textClassName="global-rights__countries__item__list__link"
                            underlined={false}
                            iconSize={24}
                            onClick={(e: any) => {
                              handleCountryClick(e, country.link);
                              handleGAevent(country.code, "list");
                            }}
                          />
                        </div>
                      );
                    })}
                  </div>
                </div>
              </Col>
            ))}
          </Row>
        </Container>
      </Container> */}
      <Modal
        className="global-rights__modal"
        show={isGpsListModalOpen}
        id="global-rights-modal"
        tabIndex={0}
      >
        <Modal.Header onHide={() => setIsGpsListModalOpen(false)}>
          <Modal.Title>{t("PRIVACY_GRRS_SELECT_COUNTRY")}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Accordion>
            <div id="test-div">
              {GpsRegions.map((region, index) => (
                <div className="sample-div" key={region.code}>
                  <Accordion.Item
                    id={
                      index === GpsRegions.length - 1
                        ? "last-region"
                        : region.code
                    }
                    testId="region-selected-btn"
                    title={region.name}
                    key={region.code}
                    activeItem={selectedModalRegion && selectedModalRegion.name}
                    onClick={(isActive) => {
                      if (isActive) {
                        setSelectedModalRegion(region);
                      } else {
                        setSelectedModalRegion(undefined);
                      }
                    }}
                  >
                    <Container>
                      <Row>
                        {region.countries.map((country) => {
                          const lastRegion = GpsRegions[GpsRegions.length - 1];
                          const lastCountry =
                            lastRegion.countries[
                              lastRegion.countries.length - 1
                            ].name;

                          return (
                            <Col md={6} sm={12} key={country.code}>
                              <Checkbox
                                id={
                                  country.name === lastCountry
                                    ? "last-language"
                                    : country.code
                                }
                                key={country.code}
                                title={country.name}
                                name="checkbox-withoutSelectBg"
                                type={CBType.withoutSelectBg}
                                onClick={() => {
                                  window.open(country.link, "_blank");
                                }}
                                svgIcon={SVG_ICONS.ARROW_RIGHT}
                              />
                            </Col>
                          );
                        })}
                      </Row>
                    </Container>
                  </Accordion.Item>
                </div>
              ))}
            </div>
          </Accordion>
        </Modal.Body>
      </Modal>
    </div>
  );
};
