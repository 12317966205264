import { SVG_ICONS } from "../components/__common/Svg/Svg";
import {
  MyDataRequestDetailsModal,
  RequestStatus,
  RequestType,
} from "../types/mydata";

export const MyDataRequestDetail: MyDataRequestDetailsModal = {
  [RequestStatus.REQUESTING]: {
    [RequestType.DOWNLOAD]: {
      title: "",
      statusLabel: "PRIVACY_MYREQUEST_TAB_REQUESTED",
      icon: SVG_ICONS.MYDATA_ACCEPTING_POPUP,
      scheduleText: "PRIVACY_REQUEST_DETAILS_EST_COMPLETION_DATE_DOWNLOAD",
      subtitle: "",
    },
    [RequestType.DELETE]: {
      title: "",
      statusLabel: "PRIVACY_MYREQUEST_TAB_REQUESTED",
      icon: SVG_ICONS.MYDATA_ACCEPTING_POPUP,
      scheduleText: "PRIVACY_REQUEST_DETAILS_EST_COMPLETION_DATE_DOWNLOAD",
      subtitle: "",
    },
    [RequestType.UNSUBSCRIBE]: {
      title: "",
      statusLabel: "PRIVACY_MYREQUEST_TAB_REQUESTED",
      icon: SVG_ICONS.MYDATA_ACCEPTING_POPUP,
      scheduleText: "PRIVACY_REQUEST_DETAILS_EST_COMPLETION_DATE_DOWNLOAD",
      subtitle: "",
    },
  },
  [RequestStatus.QUEUED]: {
    [RequestType.DOWNLOAD]: {
      title: "",
      statusLabel: "PRIVACY_MYREQUEST_TAB_REQUESTED",
      icon: SVG_ICONS.MYDATA_ACCEPTING_POPUP,
      scheduleText: "PRIVACY_REQUEST_DETAILS_EST_COMPLETION_DATE_DOWNLOAD",
      subtitle: "",
    },
    [RequestType.DELETE]: {
      title: "",
      statusLabel: "PRIVACY_MYREQUEST_TAB_REQUESTED",
      icon: SVG_ICONS.MYDATA_ACCEPTING_POPUP,
      scheduleText: "PRIVACY_REQUEST_DETAILS_EST_COMPLETION_DATE_DOWNLOAD",
      subtitle: "",
    },
    [RequestType.UNSUBSCRIBE]: {
      title: "",
      statusLabel: "PRIVACY_MYREQUEST_TAB_REQUESTED",
      icon: SVG_ICONS.MYDATA_ACCEPTING_POPUP,
      scheduleText: "PRIVACY_REQUEST_DETAILS_EST_COMPLETION_DATE_DOWNLOAD",
      subtitle: "",
    },
  },
  [RequestStatus.PROCESSING]: {
    [RequestType.DOWNLOAD]: {
      title: "",
      subtitle: "PRIVACY_REQUEST_DETAILS_PROCESSING_NOCANCEL",
      statusLabel: "PRIVACY_MYDATA_STATUS_PROCESSING",
      icon: SVG_ICONS.MYDATA_PROCESSING_POPUP,
      scheduleText: "Schedule to be complete on",
    },
    [RequestType.DELETE]: {
      title: "",
      subtitle: "PRIVACY_REQUEST_DETAILS_PROCESSING_NOCANCEL",
      statusLabel: "PRIVACY_MYDATA_STATUS_PROCESSING",
      icon: SVG_ICONS.MYDATA_PROCESSING_POPUP,
      scheduleText: "Schedule to be complete on",
    },
    [RequestType.UNSUBSCRIBE]: {
      title: "",
      subtitle: "PRIVACY_REQUEST_DETAILS_PROCESSING_NOCANCEL",
      statusLabel: "PRIVACY_MYDATA_STATUS_PROCESSING",
      icon: SVG_ICONS.MYDATA_PROCESSING_POPUP,
      scheduleText: "Schedule to be complete on",
    },
  },
  [RequestStatus.COMPLETED]: {
    [RequestType.DOWNLOAD]: {
      title: "PRIVACY_REQUEST_DETAILS_COMPLETED_DOWNLOAD",
      subtitle: "PRIVACY_REQUEST_DETAILS_COMPLETED_DOWNLOAD_SEND_FILE_PASSWORD",
      statusLabel: "PRIVACY_MYDATA_STATUS_COMPLETED",
      icon: SVG_ICONS.MYDATA_COMPLETED_POPUP,
    },
    [RequestType.DELETE]: {
      title: "PRIVACY_REQUEST_DETAILS_COMPLETED_DELETE",
      subtitle: "PRIVACY_REQUEST_DETAILS_DELETED_NORESTORE",
      statusLabel: "PRIVACY_MYDATA_STATUS_COMPLETED",
      icon: SVG_ICONS.MYDATA_COMPLETED_POPUP,
    },
    [RequestType.UNSUBSCRIBE]: {
      title: "PRIVACY_REQUEST_DETAILS_COMPLETED_OPTOUT",
      subtitle: "PRIVACY_REQUEST_DETAILS_COMPLETED_OPTOUT_DISCLAIMER",
      statusLabel: "PRIVACY_MYDATA_STATUS_COMPLETED",
      icon: SVG_ICONS.MYDATA_COMPLETED_POPUP,
    },
  },
  [RequestStatus.EXPIRED]: {
    [RequestType.DOWNLOAD]: {
      title: "PRIVACY_REQUEST_DETAILS_EXPIRED",
      statusLabel: "PRIVACY_MYREQUEST_TAB_EXPIRED",
      icon: SVG_ICONS.MYDATA_EXPIRED_POPUP,
      subtitle: "PRIVACY_REQUEST_DETAILS_EXPIRED_DISCLAIMER",
    },
    [RequestType.DELETE]: {
      title: "PRIVACY_REQUEST_DETAILS_EXPIRED",
      statusLabel: "PRIVACY_MYREQUEST_TAB_EXPIRED",
      icon: SVG_ICONS.MYDATA_EXPIRED_POPUP,
      subtitle: "",
    },
    [RequestType.UNSUBSCRIBE]: {
      title: "PRIVACY_REQUEST_DETAILS_EXPIRED",
      statusLabel: "PRIVACY_MYREQUEST_TAB_EXPIRED",
      icon: SVG_ICONS.MYDATA_EXPIRED_POPUP,
      subtitle: "",
    },
  },
  [RequestStatus.CANCELED]: {
    [RequestType.DOWNLOAD]: {
      title: "PRIVACY_CANCEL_REQUEST_MESSAGE_TEXT",
      subtitle: "PRIVACY_REQUEST_DETAILS_CANCELED_DISCLAIMER",
      statusLabel: "PRIVACY_MYDATA_STATUS_CANCELED",
      icon: SVG_ICONS.MYDATA_CANCELED_POPUP,
    },
    [RequestType.DELETE]: {
      title: "PRIVACY_CANCEL_REQUEST_MESSAGE_TEXT",
      subtitle: "PRIVACY_REQUEST_DETAILS_CANCELED_DISCLAIMER",
      statusLabel: "PRIVACY_MYDATA_STATUS_CANCELED",
      icon: SVG_ICONS.MYDATA_CANCELED_POPUP,
    },
    [RequestType.UNSUBSCRIBE]: {
      title: "The application for opt-out has been canceled.",
      subtitle: "PRIVACY_REQUEST_DETAILS_CANCELED_DISCLAIMER",
      statusLabel: "PRIVACY_MYDATA_STATUS_CANCELED",
      icon: SVG_ICONS.MYDATA_CANCELED_POPUP,
    },
  },
  [RequestStatus.DENIED]: {
    [RequestType.DOWNLOAD]: {
      title: "PRIVACY_MYDATA_REQ_ERROR_TITLE",
      subtitle: "PRIVACY_MYDATA_REQ_ERROR_BODY",
      statusLabel: "PRIVACY_MYDATA_STATUS_FAILED",
      icon: SVG_ICONS.MYDATA_DENIED_POPUP,
    },
    [RequestType.DELETE]: {
      title: "PRIVACY_MYDATA_REQ_ERROR_TITLE",
      subtitle: "PRIVACY_MYDATA_REQ_ERROR_BODY",
      statusLabel: "PRIVACY_MYDATA_STATUS_FAILED",
      icon: SVG_ICONS.MYDATA_DENIED_POPUP,
    },
    [RequestType.UNSUBSCRIBE]: {
      title: "PRIVACY_MYDATA_REQ_ERROR_TITLE",
      subtitle: "PRIVACY_MYDATA_REQ_ERROR_BODY",
      statusLabel: "PRIVACY_MYDATA_STATUS_FAILED",
      icon: SVG_ICONS.MYDATA_DENIED_POPUP,
    },
  },
};
