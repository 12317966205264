import Lottie from "lottie-react";
import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import Loading from "../../../assets/images/gif/sdap-loading.json";
import { ROUTES } from "../../../configs/routes";
import { useAuth } from "../../../hooks/auth-hooks";
import {
  DISABLED_FEATURES,
  useFeatureToggle,
} from "../../../utils/feature-toggle/useFeatureToggle";
import "./DigitalLegacyAuth.scss";

export const DigitalLegacyAuth: React.FC = () => {
  const history = useHistory();
  const { login } = useAuth();
  const { isDisabled } = useFeatureToggle();

  useEffect(() => {
    // NOTE:
    // Remove this condition once digital legacy has been released.
    // Remove `DIGITAL_LEGACY` from `DISABLED_FEATURES` enum as well.
    if (isDisabled(DISABLED_FEATURES.DIGITAL_LEGACY)) {
      history.replace(ROUTES.ERROR_404);
      return;
    }

    login(ROUTES.MYDATA, true);
  }, []);

  return (
    <>
      <Lottie
        className="dl-route__loading"
        animationData={Loading}
        loop={true}
        style={{ width: 60, height: 60 }}
      />
    </>
  );
};

export default DigitalLegacyAuth;
