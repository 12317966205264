export enum REQUEST_STATUS {
  EXPIRED = "E",
  COMPLETED = "C",
  COMPLETED_30DAYS = "E",
  REQUESTING = "W",
  PROCESSING = "T",
  CANCELED = "S",
  FAILED = "F",
  DENIED = "D",
  QUEUED = "Q",
}

export const RequestStatusLabel = {
  [REQUEST_STATUS.EXPIRED]: "PRIVACY_MYDATA_STATUS_EXPIRED",
  [REQUEST_STATUS.COMPLETED]: "PRIVACY_MYDATA_STATUS_COMPLETED",
  [REQUEST_STATUS.COMPLETED_30DAYS]: "PRIVACY_MYDATA_STATUS_EXPIRED",
  [REQUEST_STATUS.REQUESTING]: "PRIVACY_MYDATA_STATUS_REQUESTED",
  [REQUEST_STATUS.QUEUED]: "PRIVACY_MYDATA_STATUS_REQUESTED",
  [REQUEST_STATUS.PROCESSING]: "PRIVACY_MYDATA_STATUS_PROCESSING",
  [REQUEST_STATUS.CANCELED]: "PRIVACY_MYDATA_STATUS_CANCELED",
  [REQUEST_STATUS.FAILED]: "Failed",
  [REQUEST_STATUS.DENIED]: "PRIVACY_MYDATA_STATUS_FAILED",
};
