/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import {
  ALLOW_FUNCTIONAL_COOKIES,
  ALLOW_PERFORMANCE_COOKIES,
  COOKIE_NOTICE_EXPIRY,
  COOKIE_NOTICE_EXPIRY_CODE,
  COUNTRY,
  COUNTRY_CODE,
  LANGUAGE,
  LANGUAGE_CODE,
  LANGUAGE_TRANS,
  PP_LANGUAGE,
  PP_REGION,
  REGION,
  SHOW_MEMBER_NOTICE,
  SHOW_NOTICE,
  USER_SELECTED_COUNTRY,
} from "../constants/storage";
import { Cookie } from "../services/storage";
import {
  actionSetCookieNoticeOpen,
  actionSetIsCookieNoticeAccepted,
  actionSetManageCookieOpen,
  actionSetShowNoticeOpen,
} from "../store/actions/layout-actions";
import GoogleAnalytics from "../utils/GoogleAnalytics";
import { generateToken } from "../utils/UtilsTokenGenerator";

export const useCookie = () => {
  const dispatch = useDispatch();
  const [isFCAllowed, setFCAllowed] = useState<boolean>(true);
  const [isPCAllowed, setPCAllowed] = useState<boolean>(true);
  const [isCookieNoticeOpen, setCookieNoticeOpen] = useState<boolean>(false);
  const [isManageCookieOpen, setManageCookieOpen] = useState<boolean>(false);
  const [isShowMemberNotice, setShowMemberNotice] = useState<boolean>(false);
  const [isShowNotice, setShowNotice] = useState<boolean>(false);
  const lsAllowPerformanceCookies = localStorage.getItem(
    ALLOW_PERFORMANCE_COOKIES
  );
  const lsAllowFunctionalCookies = localStorage.getItem(
    ALLOW_FUNCTIONAL_COOKIES
  );
  const showCookieMemeberNotice = Cookie.get(SHOW_MEMBER_NOTICE);
  const showNotice = Cookie.get(SHOW_NOTICE);

  useEffect(() => {
    if (localStorage.getItem(ALLOW_PERFORMANCE_COOKIES) === "true") {
      setPCAllowed(true);
    } else {
      GoogleAnalytics.disableTracking();
      setPCAllowed(false);
    }

    if (localStorage.getItem(ALLOW_FUNCTIONAL_COOKIES) === "true") {
      setFCAllowed(true);
    } else {
      setFCAllowed(false);
    }
  }, [lsAllowPerformanceCookies, lsAllowFunctionalCookies]);

  useEffect(() => {
    if (showCookieMemeberNotice === "false") {
      setShowMemberNotice(false);
    } else {
      setShowMemberNotice(true);
    }
  }, [showCookieMemeberNotice]);

  useEffect(() => {
    if (showNotice === "false") {
      setShowNotice(false);
    } else {
      setShowNotice(true);
    }
  }, [showNotice]);

  useEffect(() => {
    const lsCookieNoticeExpiryCode = localStorage.getItem(
      COOKIE_NOTICE_EXPIRY_CODE
    );
    const cookieExpiryCode = Cookie.get(COOKIE_NOTICE_EXPIRY);

    if (lsCookieNoticeExpiryCode !== cookieExpiryCode) {
      setCookieNoticeOpen(true);
      localStorage.removeItem(ALLOW_FUNCTIONAL_COOKIES);
      localStorage.removeItem(ALLOW_PERFORMANCE_COOKIES);
    } else {
      dispatch(actionSetIsCookieNoticeAccepted(true));
    }
  }, []);

  const setCookiePreference = (
    isFunctionalCookiesAllowed: boolean = false,
    isPerformanceCookiesAllowed: boolean = false
  ) => {
    localStorage.setItem(
      ALLOW_FUNCTIONAL_COOKIES,
      String(isFunctionalCookiesAllowed)
    );
    localStorage.setItem(
      ALLOW_PERFORMANCE_COOKIES,
      String(isPerformanceCookiesAllowed)
    );

    setFCAllowed(isFunctionalCookiesAllowed);
    setPCAllowed(isPerformanceCookiesAllowed);

    setCookieNoticeOpen(false);
    setCookieNoticeExpiry();
    setManageCookieOpen(false);

    if (!isFunctionalCookiesAllowed) removeFunctionalCookies();
    dispatch(actionSetCookieNoticeOpen(false));
    dispatch(actionSetIsCookieNoticeAccepted(true));
  };

  const setCookieNoticeExpiry = () => {
    const cookieExpiryCode = generateToken(32);
    Cookie.set(COOKIE_NOTICE_EXPIRY, cookieExpiryCode, { expires: 186 });
    localStorage.setItem(COOKIE_NOTICE_EXPIRY_CODE, cookieExpiryCode);
  };

  const setCookieShowMemberNotice = () => {
    if (localStorage.getItem(ALLOW_FUNCTIONAL_COOKIES) === "true") {
      Cookie.set(SHOW_MEMBER_NOTICE, "false");
    }
  };

  const setCookieShowNotice = () => {
    dispatch(actionSetShowNoticeOpen(false));
    if (localStorage.getItem(ALLOW_FUNCTIONAL_COOKIES) === "true") {
      Cookie.set(SHOW_NOTICE, "false");
    }
  };

  const removeFunctionalCookies = () => {
    Cookie.remove(LANGUAGE_TRANS);
    Cookie.remove(REGION);
    Cookie.remove(COUNTRY);
    Cookie.remove(COUNTRY_CODE);
    Cookie.remove(LANGUAGE);
    Cookie.remove(LANGUAGE_CODE);
    Cookie.remove(PP_REGION);
    Cookie.remove(PP_LANGUAGE);
    Cookie.remove(SHOW_MEMBER_NOTICE);
    Cookie.remove(USER_SELECTED_COUNTRY);
  };

  const setManageCookieModalOpen = (val: boolean) => {
    dispatch(actionSetManageCookieOpen(val));
  };

  const setCookieNoticeModalOpen = (val: boolean) => {
    dispatch(actionSetCookieNoticeOpen(val));
  };

  return {
    setCookiePreference,
    setCookieNoticeExpiry,
    setManageCookieOpen,
    setCookieNoticeOpen,
    setFCAllowed,
    setPCAllowed,
    setManageCookieModalOpen,
    setCookieShowMemberNotice,
    setShowNotice,
    setCookieShowNotice,
    setCookieNoticeModalOpen,
    isManageCookieOpen,
    isFCAllowed,
    isPCAllowed,
    isCookieNoticeOpen,
    isShowMemberNotice,
    isShowNotice,
  };
};
