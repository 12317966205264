import React, { useEffect } from "react";
// import * as clipboard from "clipboard-polyfill"; // polyfill for clipboard API
import "./ErrorDetail.scss";
import { Svg, SVG_ICONS } from "../__common/Svg/Svg";
import { Typography } from "../__common/Typography/Typography";
import {
  error400,
  error403,
  error404,
  error500,
  error502,
  error503,
  errorIE,
  errorMaintenance,
} from "../../constants/pageErrorTypes";
import { Container } from "../Grid/Grid";
import { useTranslation } from "react-i18next";
import { Button } from "../__common/_controls";
import { API_BASE_URL } from "../../constants/api";
import { INVALID_PAGE } from "../../constants/storage";

enum variants {
  error400,
  error403,
  error404,
  error500,
  error502,
  error503,
  errorIE,
  errorMaintenance,
}

interface Props {
  variant: keyof typeof variants;
}

export const ErrorDetail: React.FC<Props> = (props) => {
  const { t } = useTranslation();
  const { variant } = props;

  const getErrorType = (variant: keyof typeof variants) => {
    switch (variant) {
      case "error400":
        return error400;
      case "error403":
        return error403;
      case "error404":
        return error404;
      case "error500":
        return error500;
      case "error502":
        return error502;
      case "error503":
        return error503;
      case "errorIE":
        return errorIE;
      case "errorMaintenance":
        return errorMaintenance;
      default:
        return error400;
    }
  };

  const getIcon = (variant: keyof typeof variants) => {
    switch (variant) {
      case "error400":
        return SVG_ICONS.ERROR_400;
      case "error403":
        return SVG_ICONS.ERROR_403;
      case "error404":
        return SVG_ICONS.ERROR_404;
      case "error500":
        return SVG_ICONS.ERROR_500;
      case "error502":
        return SVG_ICONS.ERROR_502;
      case "error503":
        return SVG_ICONS.ERROR_503;
      case "errorIE":
        return SVG_ICONS.ERROR_IE;
      case "errorMaintenance":
        return SVG_ICONS.ERROR_MAINTENANCE;
      default:
        return SVG_ICONS.ERROR_400;
    }
  };

  const handleOpenWithEdge = () => {
    window.open(`microsoft-edge:${API_BASE_URL}`, "_self");
  };

  const handleCopyToClipboard = () => {
    // clipboard.writeText(API_BASE_URL);
    navigator.clipboard.writeText(API_BASE_URL);
  };

  const errorType = getErrorType(variant);
  useEffect(() => {
    sessionStorage.setItem(INVALID_PAGE, "true");

    return () => {
      sessionStorage.removeItem(INVALID_PAGE);
    };
  }, []);

  return (
    <div className="errorDetail__container">
      <Container>
        <div className="errorDetail">
          <div className="errorDetail__content">
            <Svg
              icon={getIcon(variant)}
              className="errorDetail__content__icon"
            />
            <div className="errorDetail__content__description">
              <Typography
                className="errorDetail__content__description__title"
                variant="title3"
              >
                {t(errorType.errorTitle)}
              </Typography>
              <Typography
                className="errorDetail__content__description__caption"
                variant="body2"
              >
                {t(errorType.errorMessage)}
              </Typography>
            </div>
            {variant === "errorIE" && (
              <div className="errorDetail__content__buttons">
                <Button
                  title={t("PRIVACY_UNSUPPORTED_BROWSER_BTN1")}
                  className="errorDetail__content__buttons__btn1"
                  onClick={handleOpenWithEdge}
                />
                <Button
                  title={t("PRIVACY_UNSUPPORTED_BROWSER_BTN2")}
                  className="errorDetail__content__buttons__btn2"
                  onClick={handleCopyToClipboard}
                />
              </div>
            )}
          </div>
        </div>
      </Container>
    </div>
  );
};
