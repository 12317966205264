import { CustomAction } from "..";
import { languageLayoutDefaults } from "../../data/defaults";
import { HubCountry } from "../../types/landing-page";
import { LAYOUT_ACTION_TYPES } from "../actions-types";

export interface LayoutState {
  region: string;
  country: string;
  countryCode: string;
  language: string;
  languageCode: string;
  languageTrans: string;
  browserLanguage: string;
  isRTL: boolean;
  isManageCookieOpen: boolean;
  isShowNotice: boolean;
  isHighContrast: boolean;
  isShowCookieNotice: boolean;
  isShowSurvey: boolean;
  isCookieNoticeAccepted: boolean;
  ttsVoices: Array<SpeechSynthesisVoice>;
  hideChangeLocationDrawer: boolean;
  selectedCountry: HubCountry;
}

const defaultState: LayoutState = languageLayoutDefaults;

export const layoutReducer = (state = defaultState, action: CustomAction) => {
  switch (action.type) {
    case LAYOUT_ACTION_TYPES.SET_LAYOUT_LANGUAGE:
      return {
        ...state,
        ...action.data,
      };

    case LAYOUT_ACTION_TYPES.SET_MANAGE_COOKIE_OPEN:
      return {
        ...state,
        isManageCookieOpen: action.data,
      };
    case LAYOUT_ACTION_TYPES.SET_SHOW_NOTICE_OPEN:
      return {
        ...state,
        isShowNotice: action.data,
      };

    case LAYOUT_ACTION_TYPES.SET_HIGHT_CONTRAST:
      return {
        ...state,
        isHighContrast: action.data,
      };
    case LAYOUT_ACTION_TYPES.SET_SHOW_COOKIE_NOTICE_OPEN:
      return {
        ...state,
        isShowCookieNotice: action.data,
      };
    case LAYOUT_ACTION_TYPES.SET_SHOW_SURVEY_OPEN:
      return {
        ...state,
        isShowSurvey: action.data,
      };
    case LAYOUT_ACTION_TYPES.SET_TTS_VOICES:
      return {
        ...state,
        ttsVoices: action.data,
      };
    case LAYOUT_ACTION_TYPES.SET_SELECTED_COUNTRY:
      return {
        ...state,
        selectedCountry: action.data,
      };
    case LAYOUT_ACTION_TYPES.SET_IS_COOKIE_NOTICE_ACCEPTED:
      return {
        ...state,
        isCookieNoticeAccepted: action.data,
      };
    case LAYOUT_ACTION_TYPES.SET_HIDE_CHANGE_LOCATION_DRAWER:
      return {
        ...state,
        hideChangeLocationDrawer: action.data,
      };
    case LAYOUT_ACTION_TYPES.RESET_LAYOUT_STATE:
      return state;
    default:
      return state;
  }
};
