import React, { useState } from "react";

import "./PrivacyPolicy.scss";
import PrivacyIcon from "../../../assets/images/png/privacy_icon.png";
import { Image } from "../../../components/__common/Image";
import { Typography } from "../../../components/__common/Typography/Typography";
import { Container } from "../../../components/Grid/Grid";
import { useEffect } from "react";
import { LinkButton, TTSButton } from "../../../components/__common/_controls";
import { Svg, SVG_ICONS } from "../../../components/__common/Svg/Svg";
import { API_BASE_URL } from "../../../constants/api";
import { VersionHistoryModal } from "../../../components/VersionHistoryModal/VersionHistoryModal";
import { VersionHistory } from "../../../types/version-history";
import { VersionHistories } from "../../../data/version-history";
import { useSelector } from "react-redux";
import { ApplicationState } from "../../../store";
import { PrivacyState } from "../../../store/reducers/privacy-reducer";
import { LayoutState } from "../../../store/reducers/layout-reducer";
import { LanguageModal } from "../../../components/LanguageModal/LanguageModal";
import { useTranslation } from "react-i18next";
import {
  EVENT_CATEGORY_PRIVACY_POLICY,
  EVENT_ACTION_PP_CHANGE_VERSION,
  EVENT_ACTION_PP_DOWNLOAD_PRIVACY_POLICY,
} from "../../../constants/analytics";
import { UtilsLocale } from "../../../utils/UtilsLocale";
import FocusTrapping from "../../../components/__hoc/FocusTrapping";
import GoogleAnalytics from "../../../utils/GoogleAnalytics";

export const PrivacyPolicy: React.FC = () => {
  const { t } = useTranslation();
  const [isHistoryOpen, setHistoryOpen] = useState(false);
  const [isLanguageOpen, setLanguageOpen] = useState(false);
  const [ppSource, setPpSource] = useState(
    `${API_BASE_URL}/privacy-policy/en.html`
  );
  const [isIframeLoaded, setIsIframeLoaded] = useState(false);
  const [selectedHistory, setSelectedHistory] = useState<VersionHistory>(
    VersionHistories.filter((history) => history.isLatest)[0]
  );
  const privacyState = useSelector<ApplicationState, PrivacyState>(
    (state) => state.privacy
  );
  const layoutState = useSelector<ApplicationState, LayoutState>(
    (state) => state.layout
  );
  const { language } = privacyState;
  const { isHighContrast } = layoutState;

  useEffect(() => {
    // Due to delayed layout calculation upon refresh, IFRAME is not loaded, adding a setTimeout will trigger a reflow after component mounts.
    const iframeReflow = setTimeout(() => {
      setIsIframeLoaded(true);
    }, 0);

    return () => {
      clearTimeout(iframeReflow);
    };
  }, []);

  useEffect(() => {
    if (language !== "") {
      const ppExist = UtilsLocale.hasPrivacyPolicy(language);
      if (ppExist) {
        setPpSource(`${API_BASE_URL}/privacy-policy/${language}.html`);
      } else {
        setPpSource(`${API_BASE_URL}/privacy-policy/en.html`);
      }
    }
  }, [language]);

  const iframeLoaded = () => {
    const iFrameID: HTMLIFrameElement = document.getElementById(
      "privacy-id"
    ) as HTMLIFrameElement;
    if (iFrameID) {
      const html =
        iFrameID.contentWindow?.document.getElementsByTagName("html")[0];

      if (isHighContrast) {
        html?.setAttribute("data-theme", "hc");
      } else {
        html?.removeAttribute("data-theme");
      }

      iFrameID.height =
        iFrameID.contentWindow!.document?.body?.scrollHeight + 50 + "px";
    }
  };

  useEffect(() => {
    iframeLoaded();
    // eslint-disable-next-line
  }, [isHighContrast]);

  useEffect(() => {
    if (isLanguageOpen) {
      document.getElementById("language-modal-tts")?.focus();
    }
  }, [isLanguageOpen]);

  useEffect(() => {
    if (isHistoryOpen) {
      document.getElementById("version-modal-tts")?.focus();
    }
  }, [isHistoryOpen]);

  useEffect(() => {
    window.addEventListener("resize", iframeLoaded);

    return () => {
      window.removeEventListener("resize", iframeLoaded);
    };
    // eslint-disable-next-line
  }, [isHighContrast]);

  const hasVersionHistory = (): boolean => {
    return privacyState.language === "ko-KR";
  };

  const handleSelectHistory = (history: VersionHistory) => {
    if (hasVersionHistory()) {
      setSelectedHistory(history);
      if (!history.isLatest) {
        setPpSource(
          `${API_BASE_URL}/privacy-policy/ko-KR/${history.date}.html`
        );
      } else {
        setPpSource(`${API_BASE_URL}/privacy-policy/ko-KR.html`);
      }

      setHistoryOpen(false);
      GoogleAnalytics.triggerEvent(
        EVENT_CATEGORY_PRIVACY_POLICY,
        EVENT_ACTION_PP_CHANGE_VERSION
      );
    }
  };

  return (
    <div className="privacy-policy">
      <FocusTrapping
        isActive={isHistoryOpen}
        elements="div.modal-body, div.title2, svg, button, input"
      >
        <VersionHistoryModal
          headertestid="onhide-history-modal"
          selectedHistory={selectedHistory}
          show={isHistoryOpen}
          onHide={() => setHistoryOpen(false)}
          onHistorySelect={handleSelectHistory}
        />
      </FocusTrapping>
      <FocusTrapping
        isActive={isLanguageOpen}
        elements="div.modal-body, div.title2, svg, button, input"
      >
        <LanguageModal
          headertestid="onhide-language-modal"
          key={1}
          show={isLanguageOpen}
          isSiteLanguage={false}
          onHide={() => setLanguageOpen(false)}
        />
      </FocusTrapping>
      <div className="privacy-policy__title-section">
        <Image image={PrivacyIcon} width={48} height={48} />
        <Typography
          variant="h2"
          className="privacy-policy__title-section__title"
        >
          {t("PRIVACY_POLICY_GPP_PAGE_TITLE")}
          <TTSButton value={t("PRIVACY_POLICY_GPP_PAGE_TITLE")} />
        </Typography>

        <div className="privacy-policy__title-section__buttons">
          {hasVersionHistory() && (
            <LinkButton
              testId="toggle-version-history-modal"
              to="#"
              textClassName={
                "privacy-policy__title-section__buttons__version-history"
              }
              title={t("PRIVACY_POLICY_GPP_BTN_VERSION_HISTORY")}
              icon={SVG_ICONS.PRIVACY_POLICY_HISTORY}
              iconPosition="left"
              iconSize={24}
              underlined={false}
              onClick={() => setHistoryOpen(true)}
            />
          )}

          <LinkButton
            to="#"
            testId="pp-gpp-button-change-lang"
            textClassName={"privacy-policy__title-section__buttons__language"}
            title={t("PRIVACY_POLICY_GPP_BTN_CHANGE_LANGUAGE")}
            icon={SVG_ICONS.PRIVACY_POLICY_LANGUAGE}
            iconPosition="left"
            iconSize={24}
            underlined={false}
            onClick={() => setLanguageOpen(true)}
          />
          <a
            data-testid="trigger-download-button"
            download
            href={ppSource}
            className="privacy-policy__title-section__buttons__download"
            onClick={() =>
              GoogleAnalytics.triggerEvent(
                EVENT_CATEGORY_PRIVACY_POLICY,
                EVENT_ACTION_PP_DOWNLOAD_PRIVACY_POLICY
              )
            }
          >
            <Svg
              icon={SVG_ICONS.PRIVACY_POLICY_DOWNLOAD}
              width={24}
              height={24}
              className="privacy-policy__title-section__buttons__download__icon"
            />
            <Typography variant="body2" weight="w700">
              {t("PRIVACY_POLICY_GPP_BTN_DOWNLOAD")}
            </Typography>
          </a>
        </div>
      </div>

      <Container>
        {isIframeLoaded && (
          <iframe
            id="privacy-id"
            src={ppSource}
            width="100%"
            title="privacy-policy"
            onLoad={iframeLoaded}
            scrolling="no"
          />
        )}
      </Container>
    </div>
  );
};
