import React, { useEffect, useState } from "react";
import { Banner } from "./Sections/Banner/Banner";
import ManagePrivacy from "./Sections/ManagePrivacy/ManagePrivacy";
import LearnMore from "./Sections/LearnMore/LearnMore";
import PersonalDataManagement from "./Sections/PersonalDataManagement/PersonalDataManagement";
import { useLoginOptionHooks } from "../../../hooks/login-option-hooks";
import { UtilsUser } from "../../../utils/UtilsUser";
import { ROUTES } from "../../../configs/routes";
import { useSelector } from "react-redux";
import { ApplicationState } from "../../../store";
import { UserState } from "../../../store/reducers/user-reducer";
import { useHub } from "../../../hooks/hub-hooks";
import {
  TURKEY_COUNTRY_CODE,
  TURKEY_SA_COUNTRY_CODE,
} from "../../../constants/countryDefaults";
import { LayoutState } from "../../../store/reducers/layout-reducer";
import { useLayout } from "../../../hooks/layout-hooks";

const LandingPage: React.FC = () => {
  const { selectedCountry } = useSelector<ApplicationState, LayoutState>(
    (state) => state.layout
  );
  const { userData, userCountryCode } = useSelector<
    ApplicationState,
    UserState
  >((state) => state.user);

  const { toggleLoginModal } = useLoginOptionHooks();
  const { hasLocalSite, countryCode } = useHub();
  const { setHideChangeLocationDrawer, removeSelectedCountry } = useLayout();
  //state
  const [isVisible, setIsVisible] = useState<boolean>(true);

  //SA COUNTRYCODE from userData
  const saCountry = userData.countryCode;
  const hasAccessLoginThruDeepLinked = localStorage.getItem(
    "hasAccessLoginThruDeepLinked"
  );

  const handleScrollOnClick = () => {
    const body = document.getElementById("main-body");
    const manage = document.getElementById("manage-privacy");
    body?.scrollTo({ top: manage?.offsetTop, behavior: "smooth" });
  };

  useEffect(() => {
    if (!userCountryCode || !saCountry) return;

    if (
      (userCountryCode !== TURKEY_COUNTRY_CODE ||
        (selectedCountry && selectedCountry.code !== TURKEY_COUNTRY_CODE)) &&
      saCountry === TURKEY_SA_COUNTRY_CODE &&
      hasLocalSite
    ) {

      setHideChangeLocationDrawer(true);
      removeSelectedCountry();

      setIsVisible(false);
    } else {
      setIsVisible(true);
    }
  }, [saCountry, hasLocalSite, countryCode, selectedCountry.code]);
  useEffect(() => {
    if (
      hasAccessLoginThruDeepLinked === "true" &&
      !UtilsUser.isUserLoggedIn()
    ) {
      toggleLoginModal({
        isLoginModalOpen: true,
        loginModalRedirect: ROUTES.ROOT,
      });
    }
    localStorage.removeItem("hasAccessLoginThruDeepLinked");
  }, [hasAccessLoginThruDeepLinked]);
  return (
    <>
      <Banner onClick={handleScrollOnClick} data-testid="landingPage-banner" />
      {isVisible && <ManagePrivacy />}
      <LearnMore />
      <PersonalDataManagement />
    </>
  );
};

export default LandingPage;
