import React, { Suspense } from "react";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";

import "./assets/styles/main.scss";
import { persistor, store } from "./store";
import Router from "./Router";
import { useI18Next } from "./i18next";
import { PersistGate } from "redux-persist/integration/react";

declare global {
  interface Window {
    GoogleAnalyticsObject?: any;
    ga?: any;
    gaData?: any;
    gaGlobal?: any;
    gaplugins?: any;
    google_tag_data?: any;
  }
}

function App() {
  const { initTranslation } = useI18Next();

  initTranslation();
  return (
    <Suspense fallback="">
      <BrowserRouter>
        <Provider store={store}>
          <PersistGate loading={null} persistor={persistor}>
            <Router />
          </PersistGate>
        </Provider>
      </BrowserRouter>
    </Suspense>
  );
}

export default App;
