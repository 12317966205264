import { useDispatch, useSelector } from "react-redux";
import { API_BASE_URL, API_ENDPOINTS } from "../constants/api";
import Http from "../services/http";
import { ApplicationState } from "../store";
import {
  actionSetSelectedServices,
  actionSetServicesLoading,
} from "../store/actions/services-actions";
import { LayoutState } from "../store/reducers/layout-reducer";
import { ServicesState } from "../store/reducers/services-reducer";
import { UserState } from "../store/reducers/user-reducer";
import { ServiceEntity, ServicesEntityRes } from "../types/services";
import { REQUEST_TYPE } from "../types/user";
import { UtilsLocale } from "../utils/UtilsLocale";
import { USER_SELECTED_COUNTRY } from "../constants/storage";
import { Cookie } from "../services/storage";

export const useServices = () => {
  const dispatch = useDispatch();

  const layoutState = useSelector<ApplicationState, LayoutState>(
    (state) => state.layout
  );

  const userState = useSelector<ApplicationState, UserState>(
    (state) => state.user
  );

  const { selectedServices } = useSelector<ApplicationState, ServicesState>(
    (state) => state.services
  );

  const setSelectedServices = (services: string[]) => {
    dispatch(actionSetSelectedServices(services));
  };

  const loadServices = async (
    requestType: REQUEST_TYPE,
    controller: AbortController
  ) => {
    try {
      dispatch(actionSetServicesLoading());
      const url = `${API_BASE_URL}${API_ENDPOINTS.SERVICES}`;
      const res: ServicesEntityRes<ServiceEntity> = await Http.post(
        url,
        {
          request_type: requestType,
          lang: layoutState.languageTrans,
          site_cc: layoutState.countryCode,
          sa_cc: UtilsLocale.convertISO3toISO2(userState.userData.countryCode),
          ...(Cookie.get(USER_SELECTED_COUNTRY) && {
            selected_country: layoutState.selectedCountry.code,
          }),
        },
        null,
        { signal: controller.signal }
      );

      return res.service_list;
    } catch (e: any) {
      console.error(e);
      return null;
    }
  };

  return { loadServices, selectedServices, setSelectedServices };
};
