import "./CountryCarousel.scss";

import React, { useEffect, useState } from "react";
import Carousel from "nuka-carousel";
import { useWindowSize } from "../../../../../../../hooks/window-hooks";
import {
  SVG_ICONS,
  Svg,
} from "../../../../../../../components/__common/Svg/Svg";
import { Container } from "../../../../../../../components/Grid/Grid";
import { Typography } from "../../../../../../../components/__common/Typography/Typography";
import { Button } from "../../../../../../../components/__common/_controls";
import { Trans, useTranslation } from "react-i18next";
import { Image } from "../../../../../../../components/__common/Image";
import { COUNTRY_FLAGS } from "../../../../../../../components/__common/Image/flagImages";
import {
  EVENT_CATEGORY_LANDING_PAGE,
  EVENT_LANDING_PAGE_LOCAL_PRIVACY,
} from "../../../../../../../constants/analytics";
import { useLayout } from "../../../../../../../hooks/layout-hooks";
import { HubCountry } from "../../../../../../../types/landing-page";
import { useSelector } from "react-redux";
import { ApplicationState } from "../../../../../../../store";
import { LayoutState } from "../../../../../../../store/reducers/layout-reducer";
import { UserState } from "../../../../../../../store/reducers/user-reducer";
import { useHub } from "../../../../../../../hooks/hub-hooks";
import { USER_SELECTED_COUNTRY } from "../../../../../../../constants/storage";
import { Cookie } from "../../../../../../../services/storage";
import GoogleAnalytics from "../../../../../../../utils/GoogleAnalytics";
import {
  TURKEY_LOCALSITE_LINK,
  TURKEY_SA_COUNTRY_CODE,
} from "../../../../../../../constants/countryDefaults";

interface Props {
  setModalOpen: (value: boolean) => void;
}

const CountryCarousel: React.FC<Props> = ({ setModalOpen }) => {
  const { isMobile } = useWindowSize();
  const { userCountryCode } = useSelector<ApplicationState, UserState>(
    (state) => state.user
  );
  const { selectedCountry, isRTL } = useSelector<ApplicationState, LayoutState>(
    (state) => state.layout
  );
  const indexInitialValue = isRTL ? 1 : 0;
  const [slideIndex, setSlideIndex] = useState(indexInitialValue);
  const { setSelectedCountry, getUserCountry } = useLayout();

  const prevIconDisabled = isMobile
    ? SVG_ICONS.CHEVRON_HUB_LEFT_MOBILE_DISABLED
    : SVG_ICONS.CHEVRON_HUB_LEFT_DISABLED;

  const prevIconEnabled = isMobile
    ? SVG_ICONS.CHEVRON_HUB_LEFT_MOBILE
    : SVG_ICONS.CHEVRON_HUB_LEFT;

  const nextIconDisabled = isMobile
    ? SVG_ICONS.CHEVRON_HUB_RIGHT_MOBILE_DISABLED
    : SVG_ICONS.CHEVRON_HUB_RIGHT_DISABLED;

  const nextIconEnabled = isMobile
    ? SVG_ICONS.CHEVRON_HUB_RIGHT_MOBILE
    : SVG_ICONS.CHEVRON_HUB_RIGHT;

  //carousel custom left control
  const renderCenterLeftControls = ({ previousSlide, currentSlide }: any) => {
    return (
      <>
        <Svg
          icon={currentSlide === 0 ? prevIconDisabled : prevIconEnabled}
          width={50}
          height={50}
          className="country-carousel__controls__button"
          onClick={previousSlide}
        />
      </>
    );
  };

  //carousel custom right control
  const renderCenterRightControls = ({
    nextSlide,
    currentSlide,
    slideCount,
  }: any) => {
    const lastSlide = slideCount - 1;
    return (
      <Svg
        icon={currentSlide === lastSlide ? nextIconDisabled : nextIconEnabled}
        width={50}
        height={50}
        className="country-carousel__controls__button"
        onClick={nextSlide}
      />
    );
  };

  useEffect(() => {
    isRTL ? setSlideIndex(1) : setSlideIndex(0);
  }, [selectedCountry]);

  useEffect(() => {
    if (userCountryCode) {
      const country = getUserCountry();
      setSelectedCountry(country);
    }
  }, [userCountryCode]);

  return (
    <div dir="ltr">
      <Carousel
        disableEdgeSwiping={true}
        dragging={false}
        className="country-carousel"
        defaultControlsConfig={{
          containerClassName: "country-carousel__controls",
          pagingDotsContainerClassName: "country-carousel__dots",
          pagingDotsClassName: "country-carousel__dots__dot",
        }}
        renderCenterLeftControls={renderCenterLeftControls}
        renderCenterRightControls={renderCenterRightControls}
        slideIndex={slideIndex}
        afterSlide={(index) => setSlideIndex(index)}
      >
        {!isRTL ? (
          <FlagContent userCountry={selectedCountry} />
        ) : (
          <MapContent setModalOpen={setModalOpen} />
        )}

        {!isRTL ? (
          <MapContent setModalOpen={setModalOpen} />
        ) : (
          <FlagContent userCountry={selectedCountry} />
        )}
      </Carousel>
    </div>
  );
};

export default CountryCarousel;

interface FlagContentProps {
  userCountry: HubCountry;
}

const FlagContent: React.FC<FlagContentProps> = ({ userCountry }) => {
  const { t } = useTranslation();
  const { isCountryNotAllowed } = useHub();
  const { selectedCountry } = useSelector<ApplicationState, LayoutState>(
    (state) => state.layout
  );
  const { userData } = useSelector<ApplicationState, UserState>(
    (state) => state.user
  );

  const saCountry = userData.countryCode;

  const handleGAEvent = () => {
    GoogleAnalytics.triggerEvent(
      EVENT_CATEGORY_LANDING_PAGE,
      `${EVENT_LANDING_PAGE_LOCAL_PRIVACY}-${selectedCountry.code}`
    );
  };

  const handlePrivacySite = () => {
    if (saCountry && saCountry === TURKEY_SA_COUNTRY_CODE) {
      window.open(TURKEY_LOCALSITE_LINK, "_self");
      return;
    }
    if (selectedCountry.link) {
      window.open(selectedCountry?.link, "_self");
    }
  };

  return (
    <Container className="country-carousel__content">
      {!isCountryNotAllowed && (
        <Image image={COUNTRY_FLAGS[selectedCountry.code]} width={88} />
      )}
      <Typography
        variant="title2"
        className="country-carousel__content__country"
        weight="wMedium"
      >
        <Trans
          i18nKey={
            !Cookie.get(USER_SELECTED_COUNTRY)
              ? "PRIVACY_HUB_COUNTRY_DISPLAY"
              : "PRIVACY_HUB_COUNTRY_DISPLAY_SELECTED"
          }
          components={{
            span: <span></span>,
          }}
          values={{ "%s": userCountry.name }}
        />
      </Typography>
      <Typography
        variant="title2"
        className="country-carousel__content__text"
        weight="wMedium"
      >
        {t("PRIVACY_HUB_COUNTRY_CONNECT_TEXT")}
      </Typography>
      <Button
        testId="right-btn"
        title={t("PRIVACY_HUB_COUNTRY_BUTTON")}
        className="country-carousel__content__button"
        onClick={() => {
          handleGAEvent();
          handlePrivacySite();
        }}
        icon={SVG_ICONS.ARROW_RIGHT}
        iconPosition="right"
        type="content"
      />
    </Container>
  );
};

interface MapContentProps {
  setModalOpen: (value: boolean) => void;
}

const MapContent: React.FC<MapContentProps> = ({ setModalOpen }) => {
  const { t } = useTranslation();

  return (
    <Container className="country-carousel__content">
      <Svg icon={SVG_ICONS.HUB_WORLD_MAP} variant="empty" />
      <Typography
        variant="title1"
        className="country-carousel__content__text"
        weight="wMedium"
      >
        {t("PRIVACY_HUB_PREFERRED_COUNTRY_TEXT")}
      </Typography>
      <Button
        testId="pref-country-btn"
        title={t("PRIVACY_HUB_PREFERRED_COUNTRY_BUTTON")}
        className="country-carousel__content__button"
        onClick={() => setModalOpen(true)}
        icon={SVG_ICONS.ARROW_RIGHT}
        iconPosition="right"
        type="content"
      />
    </Container>
  );
};
