import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import classNames from "classnames";
import "./LanguageModal.scss";
import { Container, Row, Col } from "../Grid/Grid";
import Accordion from "../__common/Accordion/Accordion";
import Modal from "../__common/Modal/Modal";
import { CBType, Checkbox } from "../__common/_controls/Checkbox/Checkbox";
import { Regions } from "../../data/regions";
import { Languages } from "../../data/languages";
import {
  Country,
  Language,
  LANGUAGE_VIEW,
  Region,
} from "../../types/countries";
import { ApplicationState } from "../../store";
import { LayoutState } from "../../store/reducers/layout-reducer";
import { useLocale } from "../../hooks/locale-hooks";
import { SetLanguage } from "../../types/layout";
import { usePrivacyPolicy } from "../../hooks/pp-hooks";
import { PrivacyState } from "../../store/reducers/privacy-reducer";
import {
  EVENT_CATEGORY_FOOTER,
  EVENT_CATEGORY_PRIVACY_POLICY,
  EVENT_CHANGE_LANGUAGE,
} from "../../constants/analytics";
import { UtilsKeyboardAccessibility } from "../../utils/UtilsKeyboardAccessibility";
import GoogleAnalytics from "../../utils/GoogleAnalytics";

interface Props {
  isSiteLanguage?: boolean;
  show: boolean;
  onHide?: () => void;
  headertestid?: string;
}

export const LanguageModal: React.FC<Props> = (props) => {
  const { t } = useTranslation();
  const { isSiteLanguage = true, show = false, onHide, headertestid } = props;
  const { updatePrivacyPolicyLanguage } = usePrivacyPolicy();
  const [activeRegion, setActiveRegion] = useState<string>("");
  const { setLanguage } = useLocale();
  const layoutState = useSelector<ApplicationState, LayoutState>(
    (state) => state.layout
  );
  const privacyState = useSelector<ApplicationState, PrivacyState>(
    (state) => state.privacy
  );
  const { countryCode, languageCode } = layoutState;

  const handleOnHideClick = () => {
    if (onHide) {
      onHide();
    }
  };

  const handleOnLanguageClick = (
    country: Country,
    language: Language,
    region: Region
  ) => {
    if (isSiteLanguage) {
      setLanguage({
        country: country.displayName,
        countryCode: country.code,
        languageCode: language.code,
        language: Languages[language.code],
        region: region.name,
        isRTL: language.isRTL,
        languageTrans: language.trans,
      } as SetLanguage);

      GoogleAnalytics.triggerEvent(
        EVENT_CATEGORY_FOOTER,
        EVENT_CHANGE_LANGUAGE
      );
    } else {
      updatePrivacyPolicyLanguage(
        `${language.code}-${country.code}`,
        region.name,
        true
      );

      GoogleAnalytics.triggerEvent(
        EVENT_CATEGORY_PRIVACY_POLICY,
        EVENT_CHANGE_LANGUAGE
      );
    }
  };

  const isChecked = (sCountryCode: string, sLanguageCode: string): boolean => {
    if (isSiteLanguage) {
      return sCountryCode === countryCode && sLanguageCode === languageCode;
    } else {
      return privacyState.language === `${sLanguageCode}-${sCountryCode}`;
    }
  };

  useEffect(() => {
    if (isSiteLanguage) {
      document
        .querySelector(".languageSelection .accordion-item.active")
        ?.scrollIntoView();
    } else {
      document
        .querySelector(".ppLanguageSelection .accordion-item.active")
        ?.scrollIntoView();
    }
  }, [show, isSiteLanguage]);

  useEffect(
    () => {
      isSiteLanguage
        ? setActiveRegion(layoutState.region)
        : setActiveRegion(privacyState.region);
    },
    // eslint-disable-next-line
    [show]
  );

  useEffect(() => {
    if (show) {
      UtilsKeyboardAccessibility.setFocus("language-modal");
    }
  }, [show]);

  useEffect(() => {
    const lastRegion = Regions[Regions.length - 1].name;

    if (activeRegion === lastRegion && activeRegion !== "") {
      UtilsKeyboardAccessibility.addTabEventListener(
        "last-language",
        "language-modal"
      );
    } else if (activeRegion !== lastRegion && activeRegion !== "") {
      UtilsKeyboardAccessibility.addTabEventListener(
        "last-region",
        "language-modal"
      );
    }

    return () => {
      UtilsKeyboardAccessibility.removeTabEventListener(
        "last-language",
        "language-modal"
      );
      UtilsKeyboardAccessibility.removeTabEventListener(
        "last-region",
        "language-modal"
      );
    };
  }, [activeRegion]);

  return (
    <Modal
      testId="language-modal"
      show={show}
      wrapperClassName={classNames("language-modal", {
        languageSelection: isSiteLanguage,
        ppLanguageSelection: !isSiteLanguage,
      })}
      id="language-modal"
      tabIndex={0}
    >
      <Modal.Header headertestid={headertestid} onHide={handleOnHideClick}>
        <Modal.Title>{t("PRIVACY_LANGUAGE_POPUP_TITLE")}</Modal.Title>
      </Modal.Header>
      <Modal.Body isNoFooter>
        <Accordion>
          {Regions.map((region, index) => (
            <Accordion.Item
              testId="accordion-item"
              title={region.name}
              key={isSiteLanguage ? region.name : index}
              id={index === Regions.length - 1 ? "last-region" : index}
              activeItem={activeRegion}
              onClick={(isActive) => {
                if (isActive) {
                  setActiveRegion(region.name);
                } else {
                  setActiveRegion("");
                }
              }}
            >
              <Container>
                <Row>
                  {region.countries.map((country, index) => {
                    return country.languages.map((language) => {
                      const displayName = `${country.displayName} / ${
                        Languages[language.code]
                      }`;

                      const lastRegion = Regions[Regions.length - 1];
                      const lastCountry =
                        lastRegion.countries[lastRegion.countries.length - 1]
                          .name;

                      if (
                        language.languageView === LANGUAGE_VIEW.SITE_LANGUAGE &&
                        !isSiteLanguage
                      ) {
                        return null;
                      }

                      if (
                        language.languageView === LANGUAGE_VIEW.PP_LANGUAGE &&
                        isSiteLanguage
                      ) {
                        return null;
                      }

                      return (
                        <Col
                          md={6}
                          sm={12}
                          key={language.code}
                          className={`${
                            isChecked(country.code, language.code)
                              ? "active"
                              : ""
                          }`}
                        >
                          <Checkbox
                            testId="onclick"
                            checkboxTestid="onkey-down"
                            id={
                              country.name === lastCountry
                                ? "last-language"
                                : index.toString()
                            }
                            key="1"
                            title={displayName}
                            name="checkbox-withoutSelectBg"
                            type={CBType.withoutSelectBg}
                            className="language-modal__checkbox"
                            checked={isChecked(country.code, language.code)}
                            onClick={() => {
                              handleOnHideClick();
                              handleOnLanguageClick(country, language, region);
                            }}
                            onKeyPress={() => {
                              handleOnHideClick();
                              handleOnLanguageClick(country, language, region);
                            }}
                          />
                        </Col>
                      );
                    });
                  })}
                </Row>
              </Container>
            </Accordion.Item>
          ))}
        </Accordion>
      </Modal.Body>
    </Modal>
  );
};
