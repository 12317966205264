import React from "react";
import Lottie from "lottie-react";
import LoadingAnimation from "../../../assets/images/gif/sdap-loading.json";
import "./Loading.scss";

const Loading = () => {
  return (
    <div className="loading-screen">
      <Lottie
        className="loading-screen__spinner"
        animationData={LoadingAnimation}
        loop={true}
        style={{ width: 60, height: 60 }}
      />
    </div>
  );
};

export default Loading;
