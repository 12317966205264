import {
  User,
  UserEmailLoginInfo,
  UserRegion,
  UserSurvey,
} from "../../types/user";
import { CustomAction } from "../index";
import { USER_ACTION_TYPES } from "../actions-types";
import { userDefaults } from "../../data/defaults";

export interface UserState {
  userData: User;
  userCountryCode: string;
  userRegion: UserRegion;
  isGeoIPLoading: boolean;
  isGetUserDataLoading: boolean;
  userSurvey: UserSurvey;
  userEmailLoginInfo: UserEmailLoginInfo;
}

const defaultState: UserState = {
  userData: userDefaults,
  userCountryCode: "",
  userRegion: {
    isFromEurope: false,
    isFromUS: false,
    isRestricted: false,
    isSAFromAmerica: false,
    isSAFromUSA: false,
    isSAFromEurope: false,
  },
  isGeoIPLoading: true,
  userSurvey: {
    isFetchingSurveyResponse: false,
    isSubmittingSurvey: false,
    hasAnsweredSurvey: false,
  },
  isGetUserDataLoading: true,
  userEmailLoginInfo: {
    fname: "",
    lname: "",
    email: "",
  },
};

export default function userReducer(
  state = defaultState,
  action: CustomAction
) {
  switch (action.type) {
    case USER_ACTION_TYPES.SET_USER: {
      return {
        ...state,
        userData: action.data,
      };
    }
    case USER_ACTION_TYPES.SET_COUNTRY_CODE: {
      return {
        ...state,
        userCountryCode: action.data,
      };
    }
    case USER_ACTION_TYPES.SET_USER_REGION: {
      return {
        ...state,
        userRegion: { ...state.userRegion, ...action.data },
        isGeoIPLoading: false,
      };
    }
    case USER_ACTION_TYPES.SET_SA_COUNTRY: {
      return {
        ...state,
        userRegion: { ...state.userRegion, ...action.data },
      };
    }
    case USER_ACTION_TYPES.SET_LOGOUT: {
      return {
        ...state,
        userData: userDefaults,
        userCountryCode: "",
      };
    }
    case USER_ACTION_TYPES.SET_USER_DATA_LOADING: {
      return {
        ...state,
        isGetUserDataLoading: action.data,
      };
    }
    case USER_ACTION_TYPES.SET_USER_RESTRICTION_FLAG: {
      return {
        ...state,
        userRegion: { ...state.userRegion, isRestricted: action.data },
      };
    }
    case USER_ACTION_TYPES.SET_USER_ANSWERED_SURVEY: {
      return {
        ...state,
        userSurvey: { ...state.userSurvey, hasAnsweredSurvey: action.data },
      };
    }
    case USER_ACTION_TYPES.SET_USER_SURVEY_RESPONSE_PENDING: {
      return {
        ...state,
        userSurvey: {
          ...state.userSurvey,
          isFetchingSurveyResponse: action.data,
        },
      };
    }
    case USER_ACTION_TYPES.SET_USER_SUBMIT_SURVEY_PENDING: {
      return {
        ...state,
        userSurvey: { ...state.userSurvey, isSubmittingSurvey: action.data },
      };
    }
    case USER_ACTION_TYPES.SET_USER_EMAIL_LOGIN_INFO: {
      return {
        ...state,
        userEmailLoginInfo: action.data,
      };
    }
    default:
      return state;
  }
}
