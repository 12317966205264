export const USER_ACTION_TYPES = {
  SET_USER: "SET_USER",
  SET_COUNTRY_CODE: "SET_COUNTRY_CODE",
  SET_USER_REGION: "SET_USER_REGION",
  SET_SA_COUNTRY: "SET_SA_COUNTRY",
  SET_LOGOUT: "SET_LOGOUT",
  SET_GEOIP_LOADING: "SET_GEOIP_LOADING",
  SET_USER_DATA_LOADING: "SET_USER_DATA_LOADING",
  SET_USER_RESTRICTION_FLAG: "SET_USER_RESTRICTION_FLAG",
  SET_USER_SURVEY_RESPONSE_PENDING: "SET_USER_SURVEY_RESPONSE_PENDING",
  SET_USER_SUBMIT_SURVEY_PENDING: "SET_USER_SUBMIT_SURVEY_PENDING",
  SET_USER_ANSWERED_SURVEY: "SET_USER_ANSWERED_SURVEY",
  SET_USER_EMAIL_LOGIN_INFO: "SET_USER_EMAIL_LOGIN_INFO",
};

export const SERVICES_ACTION_TYPES = {
  SET_SERVICES: "SET_SERVICES",
  SET_SERVICES_LOADING: "SET_SERVICES_LOADING",
  SET_SELECTED_SERVICES: "SET_SELECTED_SERVICES",
};

export const MYDATA_ACTION_TYPES = {
  SET_SUMMARY: "SET_SUMMARY",
  SET_SUMMARY_LOADING: "SET_SUMMARY_LOADING",
  SET_REQUEST: "SET_REQUEST",
  SET_REQUEST_CONFIRMATION_DETAILS: "SET_REQUEST_CONFIRMATION_DETAILS",
  SET_REQUEST_PENDING: "SET_REQUEST_PENDING",
  SET_REQUEST_DETAILS: "SET_REQUEST_DETAILS",
  SET_REQUEST_DETAILS_LOADING: "SET_REQUEST_DETAILS_LOADING",
  SET_FILE_PASSWORD_SEND: "SET_FILE_PASSWORD_SEND",
  SET_CANCEL_REQUEST_COMPLETE: "SET_CANCEL_REQUEST_COMPLETE",
  SET_CANCEL_REQUEST_PENDING: "SET_CANCEL_REQUEST_PENDING",
  SET_MYDATA_HISTORY: "SET_MYDATA_HISTORY",
  SET_MYDATA_HISTORY_LOADING: "SET_MYDATA_HISTORY_LOADING",
  SET_MYDATA_LOADMORE_HISTORY: "SET_MYDATA_LOADMORE_HISTORY",
  SET_MYDATA_HAS_MORE_HISTORY: "SET_MYDATA_HAS_MORE_HISTORY",
  SET_MYDATA_HISTORY_MORE_LOADING: "SET_MYDATA_HISTORY_MORE_LOADING",
  SET_MYDATA_HISTORY_REQ_BODY: "SET_MYDATA_HISTORY_REQ_BODY",
  SET_MYDATA_GLOBAL_SITE_LINK_POPUP_CLOSE:
    "SET_MYDATA_GLOBAL_SITE_LINK_POPUP_CLOSE",
  SET_MYDATA_HISTORY_LOADED: "SET_MYDATA_HISTORY_LOADED",
  SET_IS_SHOW_SERVICE_TOAST_ERROR: "SET_IS_SHOW_SERVICE_TOAST_ERROR",
  SET_USER_EMAIL_IS_NOT_SET: "SET_USER_EMAIL_IS_NOT_SET",
  SET_LATEST_REQUEST_TO_HISTORY: "SET_LATEST_REQUEST_TO_HISTORY",
  SET_SUMMARY_LOADED: "SET_SUMMARY_LOADED",
};

export const LAYOUT_ACTION_TYPES = {
  SET_LAYOUT_LANGUAGE: "SET_LAYOUT_LANGUAGE",
  SET_MANAGE_COOKIE_OPEN: "SET_MANAGE_COOKIE_OPEN",
  SET_SHOW_NOTICE_OPEN: "SET_SHOW_NOTICE_OPEN",
  SET_HIGHT_CONTRAST: "SET_HIGHT_CONTRAST",
  SET_SHOW_SURVEY_OPEN: "SET_SHOW_SURVEY_OPEN",
  SET_SHOW_COOKIE_NOTICE_OPEN: "SET_SHOW_COOKIE_NOTICE_OPEN",
  SET_TTS_VOICES: "SET_TTS_VOICES",
  SET_SELECTEC_COUNTRY_COOKIE: "SET_SELECTEC_COUNTRY_COOKIE",
  SET_SELECTED_COUNTRY: "SET_SELECTED_COUNTRY",
  SET_IS_COOKIE_NOTICE_ACCEPTED: "SET_IS_COOKIE_NOTICE_ACCEPTED",
  SET_LOGIN_MODAL_OPEN: "SET_LOGIN_MODAL_OPEN",
  SET_EMAIL_LOGIN_MODAL_OPEN: "SET_EMAIL_LOGIN_MODAL_OPEN",
  SET_HIDE_CHANGE_LOCATION_DRAWER: " SET_HIDE_CHANGE_LOCATION_DRAWER",
  RESET_LAYOUT_STATE: " RESET_LAYOUT_STATE",
};

export const PRIVACY_POLICY_TYPES = {
  SET_LANGUAGE_REGION: "SET_LANGUAGE_REGION",
};

export const LOGIN_OPTION_TYPES = {
  SET_LOGIN_MODAL_OPEN: "SET_LOGIN_MODAL_OPEN",
  SET_EMAIL_LOGIN_MODAL_OPEN: "SET_EMAIL_LOGIN_MODAL_OPEN",
  SET_EMAIL_OTP_MODAL_OPEN: "SET_EMAIL_OTP_MODAL_OPEN",
  SET_OTP_ERROR_MESSAGE: "SET_OTP_ERROR_MESSAGE",
};

export const SITE_SETTINGS_TYPE = {
  SET_USER_SYSTEM_PROPERTIES: "SET_USER_SYSTEM_PROPERTIES",
  SET_LOADING: "SET_LOADING",
};
