import { useEffect, useMemo, useState } from "react";
import {
  API_BASE_URL,
  API_ENDPOINTS,
  API_PUBLIC_AUTH_TOKEN,
} from "../constants/api";
import { BaseEntityRes } from "../types/base";
import Http from "../services/http";
import { useDispatch, useSelector } from "react-redux";
import { ApplicationState } from "../store";
import { UserState } from "../store/reducers/user-reducer";
import { useLoginOptionHooks } from "./login-option-hooks";
import { useUserHooks } from "./user-hooks";
import { LayoutState } from "../store/reducers/layout-reducer";
import {
  EMAIL_LOGIN_VERIFICATION_RESULT,
  INVALID_PAGE,
  REDIRECT,
} from "../constants/storage";
import { URLS } from "../constants/urls";
import { RCODES } from "../constants/rcodes";
import { LocalStorage } from "../services/storage";
import { actionSetOtpErrorMessage } from "../store/actions/login-option-actions";
import { NUMBER_ONLY } from "../constants/validation";
import { Validate } from "../utils/Validation";

interface OtpEntityRes extends BaseEntityRes {
  refresh_window: number;
}

export const useOTP = () => {
  // third party hooks
  const dispatch = useDispatch();

  // custom hooks
  const { setUserEmailLoginInfoData, getUserData } = useUserHooks();
  const { toggleEmailOtpModal, handleEmailSignin } = useLoginOptionHooks();

  // states
  const userState = useSelector<ApplicationState, UserState>(
    (state) => state.user
  );
  const { isHighContrast } = useSelector<ApplicationState, LayoutState>(
    (state) => state.layout
  );
  const [isVerifying, setIsVerifying] = useState(false);
  const [isOTPInvalid, setIsOTPInvalid] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);
  const [otp, setOtp] = useState<string[]>(["", "", "", "", "", ""]);
  const [maxAttemptReached, setMaxAttemptReached] = useState(false);

  // variables
  const OTP_EXP_TIMER = 180;
  const RESEND_TIMER = 60;
  const numberRegex = new RegExp(NUMBER_ONLY);

  const resetOTPValue = () => {
    setOtp(["", "", "", "", "", ""]);
  };

  const getStringVal = () => {
    const otpClone = [...otp];
    return otpClone.join("");
  };

  const secondsToMinutesAndSeconds = (totalSeconds: number) => {
    const minutes = Math.floor(totalSeconds / 60);
    const seconds =
      totalSeconds % 60 > 9 ? totalSeconds % 60 : `0${totalSeconds % 60}`;

    return {
      minutes: minutes,
      seconds: seconds,
    };
  };

  const [otpExpirationTimer, setOpExpirationTimer] =
    useState<number>(OTP_EXP_TIMER);
  const [resendTimer, setResendTimer] = useState<number>(RESEND_TIMER);

  const isOtpExpired = useMemo(() => {
    if (otpExpirationTimer < 1) return true;
    return false;
  }, [otpExpirationTimer]);

  const resetAllTimer = () => {
    setOpExpirationTimer(OTP_EXP_TIMER);
    setResendTimer(RESEND_TIMER);
  };

  useEffect(() => {
    let isMounted = true;
    if (!isOtpExpired) {
      const intervalId = setInterval(() => {
        if (isMounted && !isVerifying) {
          setOpExpirationTimer((otpExpirationTimer) => otpExpirationTimer - 1);
          setResendTimer((resendTimer) => resendTimer - 1);
        }
      }, 1000);

      return () => {
        clearInterval(intervalId);
        isMounted = false;
      };
    }
    // Return a no-op function to ensure consistent return
    return () => {};
  }, [isVerifying, isOtpExpired]);

  const handleKeyDown = (
    index: number,
    e: React.KeyboardEvent<HTMLInputElement>
  ) => {
    if (Validate(numberRegex, e.key)) {
      setIsDisabled(false);
    }

    if (
      e.key === "e" ||
      e.key === "E" ||
      e.key === "-" ||
      e.key === "+" ||
      e.key === "."
    )
      e.preventDefault();
    if (e.key === "ArrowLeft") {
      document.getElementById(`otp-input-${index - 1}`)?.focus();
    }
    if (e.key === "ArrowRight") {
      document.getElementById(`otp-input-${index + 1}`)?.focus();
    }
    if (e.key === "Backspace" || e.key === "Del" || e.key === "Delete") {
      if (otp[index] !== "") {
        const newOtp = [...otp];
        newOtp[index] = "";
        setOtp(newOtp);
      }
      //   move the focus to the left input field when empty
      document.getElementById(`otp-input-${index - 1}`)?.focus();
    } else {
      // validate the input if it is a number
      if (!Validate(numberRegex, e.key)) return;
      const sliceVal = e.key.split("");
      const newOtp = [...otp];
      newOtp[index] = sliceVal[sliceVal.length - 1];
      setOtp(newOtp);

      if (e.key && index < 5) {
        // move the focus + 1 to the right
        document.getElementById(`otp-input-${index + 1}`)?.focus();
      }
    }
  };

  const handlePaste = (index: number, e: any) => {
    e.preventDefault();
    setIsDisabled(false);
    const newOtp = [...otp];
    const pastedData = e.clipboardData
      .getData("text/plain")
      .slice(0, 6)
      .replace(/[^0-9]/g, "");
    if (index !== 0) {
      // paste the value starting on the index to the end of array
      const leftArr = newOtp.slice(0, index);
      const newPastedVal = pastedData.slice(0, otp.length - index);
      setOtp([...leftArr, ...newPastedVal.split("")]);
    } else {
      const arr = pastedData.split("");
      setOtp(arr);
    }
  };

  const handleRedirection = async () => {
    let redirectUri = URLS.ROOT;
    const currentUrl = window.location.pathname;
    if (INVALID_PAGE) {
      window.location.replace(URLS.ROOT);
    }
    if (sessionStorage.getItem(REDIRECT)) {
      redirectUri = `${API_BASE_URL}${sessionStorage.getItem(REDIRECT)}`;
      window.location.replace(redirectUri);
    } else {
      window.location.replace(currentUrl);
    }
  };

  const handleOTPVerification = async (otp: string) => {
    const extraHeaders = {
      Authorization: API_PUBLIC_AUTH_TOKEN,
    };
    setIsVerifying(true);
    try {
      const url = `${API_BASE_URL}${API_ENDPOINTS.VERIFY_OTP}?otp=${otp}`;
      const res: OtpEntityRes = await Http.post(url, {}, extraHeaders);

      switch (res.rcode) {
        case RCODES.SUCCESS:
          setUserEmailLoginInfoData({
            fname: "",
            lname: "",
            email: "",
          });
          await getUserData();
          LocalStorage.set(EMAIL_LOGIN_VERIFICATION_RESULT, "true");
          await handleRedirection();
          sessionStorage.removeItem(REDIRECT);
          toggleEmailOtpModal(false);
          setIsOTPInvalid(false);

          break;
        default:
          LocalStorage.set(EMAIL_LOGIN_VERIFICATION_RESULT, "false");
          setIsOTPInvalid(true);

          break;
      }
    } catch (error: any) {
      switch (error.rcode) {
        case RCODES.INVALID_OTP:
          setIsDisabled(true);
          setIsOTPInvalid(true);
          break;
        case RCODES.GUEST_BANNED_15MINS:
        case RCODES.GUEST_BANNED_30MINS:
        case RCODES.GUEST_BANNED_1HR:
        case RCODES.GUEST_BANNED_4HR:
        case RCODES.GUEST_BANNED_1D:
          setIsOTPInvalid(false);
          setIsDisabled(false);
          setMaxAttemptReached(true);
          LocalStorage.set(EMAIL_LOGIN_VERIFICATION_RESULT, "false");
          // BREAKDOWN:
          //   rmsg = "Bad Request - guest banned for 15 minutes"
          //   split: ["Bad", "Request", "-", "guest", "banned", "for", "15", "minutes"]
          //   slice(6): ["15", "minutes"]
          //   join(" "): "15 minutes"
          const duration = error.rmsg.split(" ").slice(6).join(" ");
          dispatch(actionSetOtpErrorMessage(duration));
          break;
        default:
          LocalStorage.set(EMAIL_LOGIN_VERIFICATION_RESULT, "false");
          console.error(error);
          break;
      }
    }
    setIsVerifying(false);
  };

  const resendOTP = () => {
    handleEmailSignin(
      userState.userEmailLoginInfo.fname,
      userState.userEmailLoginInfo.lname,
      userState.userEmailLoginInfo.email,
      "resend"
    );
    resetOTPValue();
    setIsVerifying(false);
    setIsOTPInvalid(false);
    resetAllTimer();
  };

  const getTimerValue = () => {
    const value =
      secondsToMinutesAndSeconds(resendTimer).minutes.toString() +
      ":" +
      secondsToMinutesAndSeconds(resendTimer).seconds.toString();
    return value;
  };

  return {
    getTimerValue,
    resendOTP,
    isOTPInvalid,
    setIsVerifying,
    isVerifying,
    otp,
    resetOTPValue,
    handlePaste,
    handleKeyDown,
    getStringVal,
    secondsToMinutesAndSeconds,
    resendTimer,
    otpExpirationTimer,
    resetAllTimer,
    handleOTPVerification,
    isOtpExpired,
    isHighContrast,
    maxAttemptReached,
    isDisabled,
  };
};
