import React, { useState, useEffect } from "react";
import classNames from "classnames";
import { Link, useHistory } from "react-router-dom";

import "./RequestDetails.scss";
import { Typography } from "../../../../../components/__common/Typography/Typography";
import {
  RequestStatus,
  RequestType,
  SummaryRequestDetails,
  SummarySubRequestDetails,
} from "../../../../../types/mydata";
import { UtilsDateTime } from "../../../../../utils/UtilsDateTIme";
import {
  RequestStatusLabel,
  REQUEST_STATUS,
} from "../../../../../constants/status";
import { Col, Container, Row } from "../../../../../components/Grid/Grid";
import { Image } from "../../../../../components/__common/Image";
import { REQUEST_TYPE } from "../../../../../types/user";
import { useTranslation } from "react-i18next";
import {
  EVENT_CATEGORY_MY_DATA,
  EVENT_REQUEST_DETAILS,
  EVENT_CATEGORY_MY_DATA_HISTORY,
} from "../../../../../constants/analytics";
import GoogleAnalytics from "../../../../../utils/GoogleAnalytics";

interface Props {
  request: SummaryRequestDetails<SummarySubRequestDetails>;
  route: string;
  isFromSummary?: boolean;
}

export const RequestDetails: React.FC<Props> = (props) => {
  const { t } = useTranslation();
  const { request, route, isFromSummary = false } = props;

  const history = useHistory();

  const [requestFirstThree, setRequestFirstThree] =
    useState<SummarySubRequestDetails[]>();
  const [requestNextThree, setRequestNextThree] =
    useState<SummarySubRequestDetails[]>();
  const [requestRemaining, setRequestRemaining] =
    useState<SummarySubRequestDetails[]>();

  useEffect(() => {
    if (
      request.request_type === REQUEST_TYPE.UNSUBSCRIBE &&
      (request.request_status === REQUEST_STATUS.REQUESTING ||
        request.request_status === REQUEST_STATUS.QUEUED)
    ) {
      request.request_status = REQUEST_STATUS.PROCESSING;
    }

    if (request.sub_request_count > 0) {
      setRequestFirstThree(request.sub_request_list.slice(0, 3));
      setRequestNextThree(request.sub_request_list.slice(3, 6));
      setRequestRemaining(
        request.sub_request_list.slice(6, request.sub_request_list.length)
      );
    }
  }, [request]);

  const handleClickRequest = (requestNumber: string) => {
    if (isFromSummary) {
      GoogleAnalytics.triggerEvent(
        EVENT_CATEGORY_MY_DATA,
        EVENT_REQUEST_DETAILS
      );
    } else {
      GoogleAnalytics.triggerEvent(
        EVENT_CATEGORY_MY_DATA_HISTORY,
        EVENT_REQUEST_DETAILS
      );
    }
    history.push(`${route}/${requestNumber}`);
  };

  return (
    <>
      <Typography
        className="mydata-request-detail__services-container__timestamp"
        variant="body3"
      >
        {UtilsDateTime.convertDateString(request.user_request_date)}
      </Typography>
      <div className="mydata-request-detail__services-container__title">
        <Typography variant="body1" weight="w700" component="span">
          {request.request_type === RequestType.DOWNLOAD
            ? t("PRIVACY_MYDATA_SUMMARY_DOWNLOAD")
            : request.request_type === RequestType.DELETE
            ? t("PRIVACY_MYDATA_SUMMARY_DELETE")
            : t("PRIVACY_MYDATA_SUMMARY_OPTOUT")}
        </Typography>
        <Typography
          className={classNames(
            "mydata-request-detail__services-container__title__status",
            `status-${request.request_status}`
          )}
          variant="flag"
          component="span"
        >
          {t(RequestStatusLabel[request.request_status as REQUEST_STATUS])}
        </Typography>
      </div>
      <Link
        to="#"
        className={`mydata-request-detail__services-container__list ${
          request.request_status === RequestStatus.EXPIRED ||
          (request.request_status === RequestStatus.CANCELED && "faded")
        }`}
        onClick={() => handleClickRequest(request.request_number || "")}
      >
        <Container>
          <Row>
            {requestFirstThree &&
              requestFirstThree.map((request) => {
                return (
                  <Col md={6} sm={12} key={request.sub_request_number}>
                    <Image
                      image={request.service_icon}
                      width={40}
                      height={40}
                      className="service-icon"
                    />
                    <Typography
                      variant="body1"
                      className="service-name"
                      component="span"
                    >
                      {request.service_name}
                    </Typography>
                  </Col>
                );
              })}
            {requestNextThree &&
              requestNextThree.length === 1 &&
              requestNextThree.map((subRequest) => {
                return (
                  <Col md={6} sm={12} key={subRequest.service_code}>
                    <Image
                      image={subRequest.service_icon}
                      width={40}
                      height={40}
                      className="service-icon"
                    />
                    <Typography
                      variant="body1"
                      className="service-name"
                      component="span"
                    >
                      {subRequest.service_name}
                    </Typography>
                  </Col>
                );
              })}
            {requestNextThree && requestNextThree.length > 1 && (
              <Col md={6} sm={12} className="more-services">
                {requestNextThree.map((subRequest) => {
                  return (
                    <Image
                      key={subRequest.service_code}
                      image={subRequest.service_icon}
                      width={40}
                      height={40}
                      className="service-icon"
                    />
                  );
                })}

                {requestRemaining && requestRemaining.length > 0 && (
                  <Typography
                    variant="body1"
                    className="service-name"
                    component="span"
                  >
                    + {requestRemaining.length} more
                  </Typography>
                )}
              </Col>
            )}
          </Row>
        </Container>
      </Link>
    </>
  );
};
